import { useState } from "react";
import LinkButton from "../General/LinkButton";
import Loading from "../General/Loading";
import Modal from "../General/Modal";
import { Event, FirestoreEventConverter } from "../../FirestoreConverters";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "reactfire";
import { doc, updateDoc } from "firebase/firestore";

interface DeleteJarProps {
  jar?: Event;
  setJarToDelete: (jar?: Event) => void
}

const DeleteJar = ({jar, setJarToDelete}: DeleteJarProps) => {
  const firestore = useFirestore()
  const navigate = useNavigate()
  const [jarIsDeleting, setJarIsDeleting] = useState(false)
  const [jarIsDeleted, setJarIsDeleted] = useState(false)
  const [error, setError] = useState<string>()

  const deleteJar = async (jar: Event) => {
    // clear any previous error
    setError(undefined)
    // set is loading
    setJarIsDeleting(true)
    try {
      const docRef = doc(firestore, "events", jar.id)
        .withConverter(FirestoreEventConverter)
      await updateDoc(docRef, {
        isDeleted: true
      })
      navigate("/jars")
    } catch (e) {
      console.log(e)
      setJarIsDeleted(false)
      setJarIsDeleting(false)
      setError("Failed to delete your jar, please try again later.")
    }
  }

  return <Modal title="Delete Jar" closeFunction={() => setJarToDelete(undefined)}>
    <div className="text-center p-2">
      {error !== undefined ?
        <p className="p-4 my-4 bg-red-200 text-red-500 border border-red-500">
          {error}
        </p>
      : null}

      {error === undefined && jarIsDeleting === false && jarIsDeleted === true ?
        <p>Your jar has ben deleted!</p>
      : null}

      {error === undefined && jarIsDeleting === true && jarIsDeleted === false ?
        <Loading />
      : null}

      {jar !== undefined && jarIsDeleted === false && jarIsDeleting === false ?
        <div className="space-y-2">
          <p>Are you sure you want to delete the jar <span className="font-bold">"{jar?.name}"</span>?</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <LinkButton
              type="button"
              clickHandler={async () => {
                await deleteJar(jar)
              }}
            >
              Yes
            </LinkButton>
            <LinkButton
              type="button"
              backgroundColour="my-olive"
              clickHandler={() => {
                setJarToDelete(undefined)
              }}
            >
              No
            </LinkButton>
          </div>
        </div>
      : null}
    </div>
  </Modal>
}

export default DeleteJar