import {Event, Gift, User } from "../../FirestoreConverters"
import Modal from "../General/Modal";
import BillTypeSelect from "./BillTypeSelect";
import CreateInstantTxnForm from "./CreateInstantTxnForm";
import { useState } from "react";
import CreateTxnForm from "./CreateTxnForm";

interface CreateBillModalProps {
  billToRepeat?: Gift;
  event: Event;
  userInfo: User;
  closeFunction: (close: boolean) => void;
  setQrToShow: (txn: Gift) => void;
}

const CreateBillModal = ({ event, userInfo, closeFunction, setQrToShow, billToRepeat }: CreateBillModalProps) => {
  const [billType, setBillType] = useState<undefined | string>(undefined)
  const [title, setTitle] = useState("Select Bill Type")
  return <Modal
    title={title}
    closeFunction={closeFunction}
  >
    {billType === undefined && billToRepeat === undefined ?
      <BillTypeSelect setType={setBillType} />
    :
    billType === "instant" && billToRepeat === undefined ?
      <CreateInstantTxnForm event={event} userInfo={userInfo} closeFunction={closeFunction} setQrToShow={setQrToShow} setTitle={setTitle} /> :
    billType === "email" || billToRepeat !== undefined ?
      <CreateTxnForm event={event} userInfo={userInfo} closeFunction={closeFunction} setTitle={setTitle} billToRepeat={billToRepeat} /> : null
    }
  </Modal>
}

export default CreateBillModal