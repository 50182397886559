import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import LinkButton from "../General/LinkButton"

const TrialVerifyForm = ({setIsVerified}: {setIsVerified: (verified: boolean) => void}) => {

  const {
    register,
    formState: {
      errors
    },
    handleSubmit
  } = useForm<{code: string}>()

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<{code: string}> = async ({code}) => {
    setIsLoading(true)
    const isVerified = code === "XiPHf1k2ez"
    setIsVerified(isVerified)
    setIsLoading(false)
  }

  return <div className="max-w-screen-sm mx-auto p-4">
    <h1 className="text-center uppercase text-xl font-bold pt-2">Controlled Live</h1>
    <p className="pt-2">
      We are currently in a controlled live phase and are currently only working with selected test users, apologies for this inconvenience. If you would like to be a test user. please email <a href="mailto:support@flossipay.com?subject=Conrtolled Live Access" className="font-bold text-my-orange hover:underline">support@flossipay.com</a>.
    </p>
    <p className="pt-2">
      As you have registered for a Flossi Account, we will be in touch when testing is complete. Thank you and we hope to see you back here again soon!
    </p>

    <p className="pt-2">
      If you have been given access, please enter your code below
    </p>
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isLoading} className="grid grid-cols-1 gap-2">
        <input
          type="text"
          {...register(
            "code",
            {
              required: "Please enter your access code"
            }
          )}
          className="border p-2 w-full max-w-96 mx-auto outline-my-orange"
        />

        <p className="font-poppins text-red-600">{errors.code && errors.code.message}</p>

        <LinkButton
          type="submit"
          disabled={isLoading}
        >
          {isLoading === true ? <>
            <div className="relative">Verifying
            <svg className="animate-spin h-6 w-6 absolute left-8 inline" viewBox="0 0 24 24">
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            </div>
          </> : <>
            Verify
          </>}
        </LinkButton>
      </fieldset>
    </form>
  </div>
}

export default TrialVerifyForm