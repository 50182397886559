import { useOutletContext } from "react-router-dom"
import { MyAccountProps } from "./MyAccount"
import LinkButton from "../General/LinkButton"
import { useEffect, useState } from "react"
import TrialVerifyForm from "../Event/TrialVerifyForm"

const TypeSelect = () => {

  const {setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()
  setDocumentTitle("Create a Jar")
  setSubTitle("What are you collecting for?")

  const [isVerified, setIsVerified] = useState(() => {
    const saved = localStorage.getItem("trial")
    return saved !== null ? JSON.parse(saved) : false
  })

  useEffect(() => {
    localStorage.setItem("trial", JSON.stringify(isVerified))
  }, [isVerified])

  if (isVerified === false) return <TrialVerifyForm setIsVerified={setIsVerified} />

  return <div className="text-center my-auto p-4 md:p-0">
    <LinkButton type="link" url="gift" backgroundColour="my-olive">Gifting</LinkButton>
    <div className="relative flex py-5 items-center">
      <div className="flex-grow border-t border-my-olive"></div>
      <span className="flex-shrink mx-4 text-my-olive">Or</span>
      <div className="flex-grow border-t border-my-olive"></div>
    </div>
    <LinkButton type="link" url="general" backgroundColour="my-olive">A General Collection</LinkButton>
  </div>
}

export default TypeSelect