import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import Hero from "../General/Hero"

const Privacy = () => {
  const pageTitle = "Privacy Policy"
  useDocumentTitle(pageTitle)
  return (
    <div className="bg-white text-left">
      <Hero
        title={pageTitle}
      />

      <div className="mx-auto max-w-screen-md text-left text-my-grey p-4 text-sm">
        <p>Last updated: 30th September 2024</p>

        <div className="space-y-2 py-2">
          <p>www.flossipay.com (our website) is provided by Flossi Pay Limited (‘we’, ‘our’ or ‘us’). We are the controller of personal data obtained via our website, meaning we are the organisation legally responsible for deciding how and for what purposes it is used.</p>
          <p>We take your privacy very seriously. Please read this privacy policy carefully as it contains important information on who we are and how and why we collect, store, use and share any information relating to you (your personal data) in connection with your use of our website. It also explains your rights in relation to your personal data and how to contact us or a relevant regulator in the event you have a complaint.</p>
          <p>We collect, use and are responsible for certain personal data about you. When we do so we are subject to the UK General Data Protection Regulation (UK GDPR).</p>
          <p>Given the nature of our website, we do not expect to collect the personal data of anyone under 16 years old. If you are aware that any personal data of anyone under 16 years old has been shared with our website please let us know so that we can delete that data.</p>
          <p>This privacy policy is divided into the following sections:</p>
          <ul className="list-['-_'] list-inside pl-4">
            <li>What this policy applies to </li>
            <li>Personal data we collect about you </li>
            <li>How your personal data is collected </li>
            <li>How and why we use your personal data </li>
            <li>Marketing </li>
            <li>Who we share your personal data with </li>
            <li>How long your personal data will be kept </li>
            <li>Transferring your personal data out of the UK  </li>
            <li>Cookies and other tracking technologies </li>
            <li>Your rights </li>
            <li>Keeping your personal data secure </li>
            <li>How to complain </li>
            <li>Changes to this privacy policy </li>
            <li>How to contact us </li>
          </ul>
        </div>

        <ol className="list-decimal list-inside font-bold text-xl space-y-2 leading-10">
          <li>
            <h1 className="inline">
              What this policy applies to
            </h1>

            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>This privacy policy relates to your use of our website only.</li>
              <li>Throughout our website we may link to other websites owned and operated by certain trusted third parties to take payment. Those third-party websites may also gather information about you in accordance with their own separate privacy policies. For privacy information relating to those third party websites, please consult their privacy policies as appropriate.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Personal data we collect about you
            </h1>

            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li className="space-y-2">
                <p>The personal data we collect about you depends on the particular activities carried out through our website. We will collect and use the following personal data about you:</p>
                <ul className="list-['-_'] list-inside pl-2 space-y-2">
                  <li>your name, address and contact information, including but not limited to email addresses and phone numbers </li>
                  <li>information to check and verify your identity, eg date of birth </li>
                  <li>your billing information, transaction and payment card or other payment method information </li>
                  <li>bank account and payment details </li>
                  <li>details of any information, feedback or other matters you give to us by phone, email, or via social media </li>
                  <li>your account details, such as username and login details </li>
                  <li>your activities on, and use of, our website </li>
                  <li>information about the services we provide to you </li>
                  <li>your contact history, occasion history and saved items </li>
                  <li>information about how you use our website and technology systems </li>
                  <li>your responses to surveys, competitions and promotions </li>
                </ul>
              </li>
              <li>You must provide this personal data to use our website and the services on it unless we tell you that you have a choice.  </li>
              <li>Sometimes you can choose if you want to give us your personal data and let us use it. Where that is the case we will tell you and give you the choice before you give the personal data to us. We will also tell you whether declining to share that personal data will have any effect on your use of our website. </li>
              <li>We collect and use this personal data for the purposes described in the section ‘How and why we use your personal data’ below. </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">How your personal data is collected</h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li className="space-y-2">
                <p>We collect personal data from you:</p>
                <ul className="list-['-_'] list-inside pl-2 space-y-2">
                  <li>directly, when you enter or send us information, such as when you register with us, contact us (including via email), send us feedback, use services via our website, post material to our website and complete customer surveys or participate in competitions via our website, and</li>
                  <li>indirectly, such as your browsing activity while on our website; we will usually collect information indirectly using the technologies explained in the section on ‘Cookies and other tracking technologies’ below</li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">How and why we use your personal data</h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li className="space-y-2">
                <p>Under data protection law, we can only use your personal data if we have a proper reason, eg:</p>
                <ul className="list-['-_'] list-inside pl-2 space-y-2">
                  <li>where you have given consent </li>
                  <li>to comply with our legal and regulatory obligations </li>
                  <li>for the performance of a contract with you or to take steps at your request before entering into a contract, or </li>
                  <li>for our legitimate interests or those of a third party </li>
                </ul>
              </li>
              <li>A legitimate interest is when we have a business or commercial reason to use your personal data, so long as this is not overridden by your own rights and interests. We will carry out an assessment when relying on legitimate interests, to balance our interests against your own. You can obtain details of this assessment by contacting us (see ‘How to contact us’ below).</li>
              <li>The table below explains what we use your personal data for and why.
                <table className="-ml-4 table-fixed text-xs">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border p-2 align-top w-1/3">What we use your personal data for </th>
                      <th className="border p-2 align-top">Our reasons </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">Create and manage your account with us </td>
                      <td className="border p-2 align-top">To perform our contract with you or to take steps at your request before entering into a contract </td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Providing services to you </td>
                      <td className="border p-2 align-top">To perform our contract with you or to take steps at your request before entering into a contract </td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Conducting checks to identify you and verify your identity or to help prevent and detect fraud against you or us</td>
                      <td className="border p-2 align-top">For our legitimate interests, ie to minimise fraud that could be damaging for you and/or us</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">To enforce legal rights or defend or undertake legal proceedings</td>
                      <td className="border p-2 align-top space-y-2">
                        <p>Depending on the circumstances:</p>
                        <ul className="list-['-_'] pl-3">
                          <li>to comply with our legal and regulatory obligations</li>
                          <li>in other cases, for our legitimate interests, ie to protect our business, interests and rights</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Customise our website and its content to your particular preferences based on a record of your selected preferences or on your use of our website</td>
                      <td className="border p-2 align-top space-y-2">
                        <p>Depending on the circumstances:</p>

                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>your consent as gathered by the separate cookies tool on our website - see ‘Cookies’ below</li>
                          <li>where we are not required to obtain your consent and do not do so, for our legitimate interests, ie to be as efficient as we can so we can deliver the best service to you at the best price</li>
                        </ul>

                        <p>If you have provided such a consent you may withdraw it at any time by deleting browser cookies or by changing the setting on the cookies tool (this will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn).</p>
                      </td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Retaining and evaluating information on your recent visits to our website and how you move around different sections of our website for analytics purposes to understand how people use our website so that we can make it more intuitive or to check our website is working as intended</td>
                      <td className="border p-2 align-top space-y-2">
                        <p>Depending on the circumstances:</p>

                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>your consent as gathered by the separate cookies tool on our website - see ‘Cookies’ below</li>

                          <li>where we are not required to obtain your consent and do not do so, for our legitimate interests, ie to be as efficient as we can so we can deliver the best service to you at the best price</li>
                        </ul>

                        <p>If you have provided such a consent you may withdraw it at any time by deleting browser cookies or by changing the setting on the cookies tool (this will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn).</p>
                      </td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Communications with you not related to marketing, including about changes to our terms or policies or changes to the services or other important notices</td>
                      <td className="border p-2 align-top space-y-2">
                        <p>Depending on the circumstances:</p>

                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>to comply with our legal and regulatory obligations</li>
                          <li>in other cases, for our legitimate interests, ie to be as efficient as we can so we can deliver the best service to you at the best price</li>
                        </ul>
                      </td>

                    </tr>
                    <tr>
                    <td className="border p-2 align-top">Protecting the security of systems and data used to provide the services</td>
                      <td className="border p-2 align-top space-y-2">
                        <p>To comply with our legal and regulatory obligations</p>
                        <p>We may also use your personal data to ensure the security of systems and data to a standard that goes beyond our legal obligations, and in those cases our reasons are for our legitimate interests, ie to protect systems and data and to prevent and detect criminal activity that could be damaging for you and/or us</p>
                      </td>
                  </tr>

                    <tr>
                      <td className="border p-2 align-top">Statistical analysis to help us understand our customer base</td>
                      <td className="border p-2 align-top">For our legitimate interests, ie to be as efficient as we can so we can deliver the best service to you at the best price</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Updating and enhancing customer records</td>
                      <td className="border p-2 align-top space-y-2">
                        <p>Depending on the circumstances:</p>

                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>to perform our contract with you or to take steps at your request before entering into a contract</li>
                          <li>to comply with our legal and regulatory obligations</li>
                          <li>where neither of the above apply, for our legitimate interests, eg making sure that we can keep in touch with our customers about existing orders and new products</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Disclosures and other activities necessary to comply with legal and regulatory obligations that apply to our business, eg to record and demonstrate evidence of your consents where relevant</td>
                      <td className="border p-2 align-top">To comply with our legal and regulatory obligations</td>
                    </tr>

                    <tr>
                    <td className="border p-2 align-top">Marketing our services to existing and former customers</td>

                      <td className="border p-2 align-top space-y-2">
                        <p>For our legitimate interests, ie to promote our business to existing and former customers</p>

                        <p>See ‘Marketing’ below for further information</p>
                      </td>
                    </tr>

                  <tr>
                      <td className="border p-2 align-top space-y-2">
                        <p>To share your personal data with members of our group and third parties that will or may take control or ownership of some or all of our business (and professional advisors acting on our or their behalf) in connection with a significant corporate transaction or restructuring, including a merger, acquisition, asset sale, initial public offering or in the event of our insolvency</p>
                        <p>In such cases information will be anonymised where possible and only shared where necessary</p>
                      </td>

                      <td className="border p-2 align-top space-y-2">
                        <p>Depending on the circumstances:</p>
                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>to comply with our legal and regulatory obligations</li>
                          <li>in other cases, for our legitimate interests, ie to protect, realise or grow the value in our business and assets</li>
                        </ul>
                      </td>
                  </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">How and why we use your personal data - in more detail </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>
                More details about how we use your personal data and why are set out in the table below.
                <table className="-ml-4 table-fixed overflow-x-scroll min-w-md text-xs">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border p-2 align-top w-1/4">Purpose </th>
                      <th className="border p-2 align-top w-1/4">Processing operation </th>
                      <th className="border p-2 align-top w-1/4">Lawful basis relied on under the UK GDPR </th>
                      <th className="border p-2 align-top w-1/4">Relevant categories of personal data</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td rowSpan={3} className="border p-2 align-top">
                        Communications with you not related to marketing, including about changes to our terms or policies or changes to the products or other important notices
                      </td>
                      <td className="border p-2 align-top">
                        Addressing and sending communications to you as required by data protection laws, ie:

                        the UK GDPR or Data Protection Act 2018
                      </td>

                      <td className="border p-2 align-top">
                        Processing is necessary for compliance with a legal obligation to which we are subject (Article 6(1)(b))
                      </td>

                      <td className="border p-2 align-top">
                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>your name, address and contact information, including email address and telephone number and company details</li>
                          <li>your account details (username)</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Addressing and sending communications to you as required by anti money laundering regulations</td>
                      <td className="border p-2 align-top">Processing is necessary for compliance with a legal obligation to which we are subject (Article 6(1)(b))</td>
                      <td className="border p-2 align-top">
                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>your name, address, date of birth and contact information, including email address and telephone number, driving licence, passport</li>
                          <li>your account details (username)</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Addressing and sending communications to you about changes to our terms or policies or changes to the products or other important notices (other than those addressed above)</td>
                      <td className="border p-2 align-top">Our legitimate interests (Article 6(1)(f)), which is to be as efficient as we can so we can deliver the best service to you</td>
                      <td className="border p-2 align-top">
                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>your name, address and contact information, including email address and telephone number</li>
                          <li>your account details (username)</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">How and why we use your personal data - sharing</h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>See <span className="font-bold">‘Who we share your personal data with’</span> for further information on the steps we will take to protect your personal data where we need to share it with others. </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Marketing</h1>

            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>We will use your personal data to send you updates (by email, text message, telephone or post) about our services, including exclusive offers, promotions or new services. </li>
              <li>We have a legitimate interest in using your personal data for marketing purposes (see above ‘How and why we use your personal data’). This means we do not need your consent to send you marketing information. If we change our marketing approach in the future so that consent is needed, we will ask for this separately and clearly. </li>
              <li className="space-y-2">
                <p>You have the right to opt out of receiving marketing communications at any time by:</p>
                <ul className="list-['-_'] pl-3 space-y-2">
                  <li>contacting us at support@flossipay.com </li>
                  <li>using the ‘unsubscribe’ link in emails or ‘STOP’ number in texts </li>
                </ul>
              </li>
              <li>We may ask you to confirm or update your marketing preferences if you ask us to provide further services in the future, or if there are changes in the law, regulation, or the structure of our business. </li>
              <li>We will always treat your personal data with the utmost respect and never sell it with other organisations for marketing purposes. </li>
              <li>For more information on your right to object at any time to your personal data being used for marketing purposes, see <span className="font-bold">‘Your rights’</span> below. </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Who we share your personal data with
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li className="space-y-2">
                <p>We routinely share personal data with:</p>
                <ul className="list-['-_'] pl-3 space-y-2">
                  <li>third parties we use to help deliver our services to you, eg payment service providers</li>
                  <li>other third parties we use to help us run our business, eg marketing agencies or website hosts and website analytics providers</li>
                  <li>our bank</li>
                </ul>
              </li>
              <li>
                We only allow those organisations to handle your personal data if we are satisfied they take appropriate measures to protect your personal data.
              </li>
              <li className="space-y-2">
                <p>We or the third parties mentioned above occasionally also share personal data with:</p>
                <ul className="list-['-_'] pl-3 space-y-2">
                  <li>our and their external auditors, eg in relation to the audit of our or their accounts, in which case the recipient of the information will be bound by confidentiality obligations</li>
                  <li>our and their professional advisors (such as lawyers and other advisors), in which case the recipient of the information will be bound by confidentiality obligations</li>
                  <li>law enforcement agencies, courts, tribunals and regulatory bodies to comply with our legal and regulatory obligations</li>
                  <li>other parties that have or may acquire control or ownership of our business (and our or their professional advisers) in connection with a significant corporate transaction or restructuring, including a merger, acquisition, asset sale, initial public offering or in the event of our insolvency—usually, information will be anonymised but this may not always be possible. The recipient of any of your personal data will be bound by confidentiality obligations</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">
              Who we share your personal data with - in more detail
            </h1>

            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>
                More details about who we share your personal data with and why are set out in the table below.
                <table className="-ml-4 table-fixed overflow-x-scroll min-w-md text-xs">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border p-2 align-top w-1/6">Recipient</th>
                      <th className="border p-2 align-top w-3/6">Processing operation (use) by recipient</th>
                      <th className="border p-2 align-top w-2/6">Relevant categories of personal data transferred to recipient</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">Cashflows Europe Limited</td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services</td>
                      <td className="border p-2 align-top">General and Sensitive Personal Data</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">VISA Europe Limited</td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services</td>
                      <td className="border p-2 align-top">General and Sensitive Personal Data</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Acquired Limited</td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services</td>
                      <td className="border p-2 align-top">General and Sensitive Personal Data</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Google LLC (Firebase) </td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services </td>
                      <td className="border p-2 align-top">General and Sensitive Personal Data </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Who we share your personal data with - further information
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>If you would like more information about who we share our data with and why, please contact us (see ‘How to contact us’ below).</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              How long your personal data will be kept
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>We will not keep your personal data for longer than we need it for the purpose for which it is used.</li>
              <li>If you stop using your account we will delete or anonymise your account data after seven years.</li>
              <li>Following the end of the of the relevant retention period, we will delete or anonymise your personal data.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Transferring your personal data out of the UK
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>Countries outside the UK have differing data protection laws, some of which may provide lower levels of protection of privacy.</li>
              <li>It is sometimes necessary for us to transfer your personal data to countries outside the UK. In those cases we will comply with applicable UK laws designed to ensure the privacy of your personal data.</li>
              <li className="space-y-2">
                <p>We will transfer your personal data to:</p>
                <ul className="list-['-_'] pl-3 space-y-2">
                  <li>our payment gateway service provider whom will encrypt and store data within the EU West-1 AWS Availability Zone</li>
                  <li>our withdrawal service provider whom will encrypt and store data within the EU West-1 AWS Availability Zone</li>
                </ul>
              </li>

              <li className="space-y-2">
                <p>Under data protection laws, we can only transfer your personal data to a country outside the UK where:</p>
                <ul className="list-['-_'] pl-3 space-y-2">
                  <li>in the case of transfers subject to UK data protection law, the UK government has decided the particular country ensures an adequate level of protection of personal data (known as an ‘adequacy regulation’) further to Article 45 of the UK GDPR.</li>
                  <li>there are appropriate safeguards in place, together with enforceable rights and effective legal remedies for you, or</li>
                  <li>a specific exception applies under relevant data protection law</li>
                </ul>
              </li>
              <li>Where we transfer your personal data outside the UK we do so on the basis of an adequacy regulation. In the event we cannot or choose not to continue to rely on either of those mechanisms at any time we will not transfer your personal data outside the UK unless we can do so on the basis of an alternative mechanism or exception provided by UK data protection law and reflected in an update to this policy.</li>
              <li>Any changes to the destinations to which we send personal data or in the transfer mechanisms we rely on to transfer personal data internationally will be notified to you in accordance with the section on ‘Changes to this privacy policy’ below.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">
              Transferring your personal data out of the UK - in more detail
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>
                More details about the countries outside the UK to which your personal data is transferred are set out in the table below.
                <table className="-ml-4 table-fixed text-xs">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border p-2 align-top w-1/4">Recipient country </th>
                      <th className="border p-2 align-top w-1/4">Recipient</th>
                      <th className="border p-2 align-top w-1/4">Processing operation (use) by recipient </th>
                      <th className="border p-2 align-top w-1/4">Lawful safeguard </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">Ireland (EU West-1 AWS Availability Zone, Dublin)</td>
                      <td className="border p-2 align-top">Cashflows Europe Limited</td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services</td>
                      <td className="border p-2 align-top">Adequacy regulation further to paragraph 5(1)(a) of Part 3 of Schedule 21 to the Data Protection Act 2018</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">Ireland (EU West-1 AWS Availability Zone, Dublin)</td>
                      <td className="border p-2 align-top">VISA Europe Limited</td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services</td>
                      <td className="border p-2 align-top">Adequacy regulation further to paragraph 5(1)(a) of Part 3 of Schedule 21 to the Data Protection Act 2018</td>
                    </tr>

                    <tr>
                      <td className="border p-2 align-top">North America (all zones) </td>
                      <td className="border p-2 align-top">Google LLC (Firebase) </td>
                      <td className="border p-2 align-top">Web and data hosting services, ie stores a copy of your personal data on computer equipment so it can be accessed by us and permitted third parties online in order to run our business and provide services </td>
                      <td className="border p-2 align-top">Adequacy regulation further to paragraph 5(1)(a) of Part 3 of Schedule 21 to the Data Protection Act 2018 </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Transferring your personal data out of the UK - further information
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>If you would like further information about data transferred outside the UK, please contact us (see ‘How to contact us’ below).</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Cookies
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>A cookie is a small text file which is placed onto your device (eg computer, smartphone or other electronic device) when you use our website. We use cookies on our website. These help us recognise you and your device and store some information about your preferences or past actions.</li>
              <li>For further information on cookies, our use of ‘cookies’, when we will request your consent before placing them and how to disable them, please request further information from us.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">
              Your rights
            </h1>
            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
              <li>You generally have the following rights, which you can usually exercise free of charge:

              <table className="-ml-4 table-fixed text-xs">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border p-2 align-top w-1/3">Right</th>
                      <th className="border p-2 align-top">Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">Access to a copy of your personal data </td>
                      <td className="border p-2 align-top">The right to be provided with a copy of your personal data </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Correction (also known as rectification) </td>
                      <td className="border p-2 align-top">The right to require us to correct any mistakes in your personal data </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Erasure (also known as the right to be forgotten) </td>
                      <td className="border p-2 align-top">The right to require us to delete your personal data - in certain situations </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Restriction of use </td>
                      <td className="border p-2 align-top">The right to require us to restrict use of your personal data in certain circumstances, eg if you contest the accuracy of the data </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Data portability </td>
                      <td className="border p-2 align-top">The right to receive the personal data you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party - in certain situations </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">To object to use </td>
                      <td className="border p-2 align-top">The right to object:
                        <ul className="list-['-_'] pl-3 space-y-2">
                          <li>at any time to your personal data being used for direct marketing (including profiling)</li>
                          <li>in certain other situations to our continued use of your personal data, eg where we use your personal data for our legitimate interests unless there are compelling legitimate grounds for the processing to continue or the processing is required for the establishment, exercise or defence of legal claims</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">Not to be subject to decisions without human involvement </td>
                      <td className="border p-2 align-top">
                        <p>The right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you</p>
                        <p>We do not make any such decisions based on data collected by our website</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 align-top">The right to withdraw consents </td>
                      <td className="border p-2 align-top">
                        <p>If you have provided us with a consent to use your personal data you have a right to withdraw that consent easily at any time</p>
                        <p>You may withdraw consents by contacting us at support@flossipay.com</p>
                        <p>Withdrawing a consent will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>For further information on each of those rights, including the circumstances in which they do and do not apply, please contact us (see ‘How to contact us’ below). You may also find it helpful to refer to the guidance from the UK’s Information Commissioner on your rights under the UK GDPR.  </li>
              <li className="space-y-2">
                <p>If you would like to exercise any of those rights, please email, call or write to us—see below: ‘How to contact us’. When contacting us please: </p>
                <ul className="list-['-_'] pl-3 space-y-2">
                  <li>provide enough information to identify yourself [(eg your full name, address and customer or matter reference number)] and any additional identity information we may reasonably request from you, and </li>
                  <li>let us know which right(s) you want to exercise and the information to which your request relates </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">
              Keeping your personal data secure
            </h1>


            <ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
    <li>We have appropriate security measures to prevent personal data from being accidentally lost, or used or accessed unlawfully. We limit access to your personal data to those who have a genuine need to access it.</li>

    <li>We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</li>

    <li>Your personal data is protected when it is stored using AES256 encryption, as well as during transfer through the use of Transport Layer Security.</li>

    <li>If you want detailed information from Get Safe Online on how to protect your personal data and other information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses</li>
  </ol>
          </li>

<li>
<h1 className="inline">
  How to complain
</h1>

<ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
  <li>Please contact us if you have any queries or concerns about our use of your personal data (see below ‘How to contact us’). We hope we will be able to resolve any issues you may have.</li>

  <li className="space-y-2">
    <p>You also have the right to lodge a complaint with:</p>

    <ul className="list-['-_'] pl-3 space-y-2">
      <li>the Information Commissioner in the UK</li>
      </ul>
  </li>

  <li>The UK’s Information Commissioner may be contacted using the details at https://ico.org.uk/make-a-complaint or by telephone: 0303 123 1113.</li>
</ol>
</li>

<li>
<h1 className="inline">
  Changes to this privacy policy
</h1>

<ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
  <li>We may change this privacy policy from time to time - when we make significant changes we will take steps to inform you, for example by including a prominent link to a description of those changes on our website for a reasonable period or by other means, such as email.</li>
</ol>
</li>
<li>
<h1 className="inline">
  How to contact us
</h1>

<ol className="list-[lower-alpha] list-outside pl-4 text-sm font-normal space-y-2">
  <li>You can contact us by post or email if you have any questions about this privacy policy or the information we hold about you, to exercise a right under data protection law or to make a complaint.</li>

  <li className="space-y-2">
    <p>Our contact details are shown below:</p>

    <ul className="list-['-_'] pl-3 space-y-2">
      <li>Post: Flossi Pay Limited, Riverside Offices, Second Floor, 26 St. Georges Quay, Lancaster, LA1 1RD</li>

      <li>Email: support@flossipay.com</li>
    </ul>
  </li>
</ol>
</li>
        </ol>
      </div>
    </div>
  )
}

export default Privacy