import LinkButton from "../../General/LinkButton"
import NewsletterSubscribeForm from "./NewsletterSubscribeForm"

const Body = () => {
  return (<>
    <div className="px-6 py-10 xl:py-20 bg-my-orange text-white">
      <div className="max-w-screen-lg mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-2 justify-items-center md:justify-center">
          <div className="text-3xl md:text-4xl md:text-left uppercase font-beloved min-h-52">
          Want to collect money without a card machine or the need to share your bank details?
          </div>
          <div className="md:text-left my-auto">
            Flossi provides an alternative payment solution. Simply share the link or display the QR code to allow monies to be collected. We are a perfect solution for Pop Up Shops, Group Collections, Wedding Gift Collections and so much more
          </div>
        </div>
        {/* <div className="uppercase text-lg pt-10 pb-10 xl:pb-0">
          Collect money online made easy
        </div> */}
      </div>
    </div>



    <div className="px-4 py-10 xl:mx-auto xl:py-20 bg-white text-my-grey">
      <h1 className="text-2xl uppercase font-beloved">
        How Flossi Works
      </h1>
      <p className="uppercase pb-8">Collecting money online has never been easier!</p>
      <div className="content-center max-w-screen-lg gap-10 grid grid-cols-1 sm:grid-cols-3 sm:mx-auto">
        <div>
        <div className="max-w-28 mx-auto align-middle text-center items-center justify-center justify-items-center">
            <img alt="Create new jar" src="/img/emptyjar-grey-icon.png" className="block w-28" />
          </div>
          <h3 className="uppercase py-2 font-bold text-lg">Create</h3>
          <p>
            Create a jar for your collection.
          </p>
          <p>
            It's free and simple!
          </p>
        </div>
        <div>
          <div className="max-w-20 my-3 mx-auto align-middle text-center items-center justify-center justify-items-center">
            <img alt="Share the occasion" src="/img/share-grey-icon.png" className="ml-3"/>
          </div>
          <h3 className="uppercase py-2 font-bold text-lg">Share</h3>
          <p>
            Share the link or QR code allowing people to easily make a payment
          </p>
        </div>
        <div>
        <div className="max-w-28 mx-auto align-middle text-center items-center justify-center justify-items-center">
            <img alt="Get paid!" src="/img/fulljar-grey-icon.png" className="" />
          </div>
          <h3 className="uppercase py-2 font-bold text-lg">Redeem</h3>
          <p>
            When you're ready, the collected funds will be transferred directly to your bank account          </p>
        </div>
        <div className="uppercase sm:col-span-3 w-full align-middle">
          <LinkButton type="link" url="/create-jar" backgroundColour="my-olive">
            Create Jar
          </LinkButton>
        </div>
      </div>
    </div>



    <div className="py-24 bg-my-orange text-white">
      <h3 className="text-2xl uppercase px-6 font-beloved">
        Want to be kept up to date?
      </h3>
      <p className="p-4 px-6">
        Sign up to our newsletter to find out about the latest news and offers!
      </p>
      <div className="max-w-md mx-auto px-4">
        <NewsletterSubscribeForm />
      </div>
    </div>



    <div className="px-6 py-10 mx-auto xl:py-28 bg-white text-my-grey">
      <h1 className="text-2xl uppercase font-beloved">
        How does contributing work?
      </h1>
      <p className="uppercase pb-8">
        SENDING MONEY ONLINE HAS NEVER BEEN SO CONVENIENT
      </p>
      <div className="content-center max-w-screen-lg gap-10 grid grid-cols-1 sm:grid-cols-3 sm:mx-auto">
        <div>
          <div className="max-w-28 mx-auto align-middle text-center items-center justify-center justify-items-center">
            <img src="/img/qr-grey-icon.png" alt="Scan icon" />
          </div>
          <h3 className="uppercase py-2 font-bold text-lg">Follow Link/Scan QR</h3>
          <p>
            Follow the link or scan the QR code provided to access the jar collection page
          </p>
        </div>
        <div>
        <div className="max-w-28 mx-auto align-middle text-center items-center justify-center justify-items-center">
              <img src="/img/money-grey-icon.png" alt="Money icon"/>
          </div>
          <h3 className="uppercase py-2 font-bold text-lg">Select</h3>
          <p>
            Enter an amount of money to send
          </p>
        </div>
        <div>
          <div className="max-w-28 mx-auto align-middle text-center items-center justify-center justify-items-center">
            <img src="/img/fulljar-grey-icon.png" alt="payment icon" />
          </div>
          <h3 className="uppercase py-2 font-bold text-lg">Send</h3>
          <p>
            Fill in your details and pay
          </p>
        </div>
      </div>
    </div>
  </>)
}

export default Body