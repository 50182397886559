import { useState } from "react";
import { BillContribution, BillTypes, ContributionTypes, Event, Gift, PaymentStatus } from "../../FirestoreConverters";
import ContextMenu from "../General/ContextMenu";
import PaymentStatusIcon from "../General/PaymentStatusIcon";

interface PendingContributoinCardProps {
  event: Event;
  contribution: Gift;
  sendBillReminder: (bill: Gift) => void;
  setQrToShow: (gift: Gift) => void;
  setBillToDelete: (gift: Gift) => void;
  recreateBill: (gift: BillContribution) => void;
}

const PendingContributionCard = ({
  event,
  contribution,
  sendBillReminder,
  setQrToShow,
  setBillToDelete,
  recreateBill,
}: PendingContributoinCardProps) => {

  const [copiedToClipboard, setCopiedToClipboard] = useState<false | string>(false)
  const copyBillLink = async (bill: Gift) => {
    if (bill.id === undefined) return
    const billUrl = `http://${window.location.host}/contribute-to/${event.id}/bill/${bill.id}`
    await navigator.clipboard.writeText(`${billUrl}?utm_source=jaradm&utm_medium=link`)
    setCopiedToClipboard(bill.id)
    setTimeout(() => {
      setCopiedToClipboard(false)
    }, 5000)
  }

  const [showMenu, setShowMenu] = useState<boolean>(false)
  return <div
    className={`py-2 px-4 border-b last:border-b-0 ${showMenu === true ? "bg-gray-100" : ""}`}
    key={contribution.id}
  >
    <div className="flex">
      <div className="flex-initial w-7/12">
        {"billType" in contribution && contribution.billType === BillTypes.INSTANT ?
          <p>
            Instant Bill
          </p>
          :
          <p>
            Bill for
            <span className="font-bold">
              {` ${contribution.cardholderFName} ${contribution.cardholderLName}`}
            </span>
          </p>
        }
        <p className="text-xs">{contribution.giftedAt.toDate().toLocaleDateString()}</p>
        {contribution.message !== undefined && contribution.message.length > 0 ?
          <p className="text-sm md:text-md"><span className="font-bold">Ref:</span> {contribution.message}</p>
        : null}

      </div>
      <div className="flex-none w-3/12 text-right my-auto text-my-orange md:text-2xl font-bold">
        &pound;{contribution.giftAmount.toFixed(2)}
      </div>
      <div className="flex-none w-1/12 text-right my-auto">
          <PaymentStatusIcon status={contribution.status as PaymentStatus} />
      </div>
      <div className="flex-none w-1/12 relative my-auto text-right">
        <ContextMenu showMenu={showMenu} setShowMenu={setShowMenu}>
          <>
            {contribution.status !== PaymentStatus.CANCELLED ?
              <button
                onClick={() => {
                  setQrToShow(contribution)
                  setShowMenu(false)
                }}
                className="text-my-orange hover:underline text-left block w-full py-3"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
                </svg>

                <span className="pl-2">
                  Show QR
                </span>
              </button>
            : null }
            {contribution.status !== PaymentStatus.CANCELLED ?
              <button
                onClick={() => copyBillLink(contribution)}
                className="text-my-orange hover:underline text-left block py-3 w-full"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                </svg>

                {copiedToClipboard === contribution.id ?
                  <span className="pl-2">
                    Copied!
                  </span>
                :
                  <span className="pl-2">
                    Copy Bill Link
                  </span>
                }
              </button>
            : null }

            {contribution.status !== PaymentStatus.CANCELLED && contribution.type === ContributionTypes.BILL && contribution.billType === BillTypes.EMAIL ?
              <button
                onClick={() => {
                  sendBillReminder(contribution)
                  setShowMenu(false)
                }}
                className="text-my-orange hover:underline text-left block py-3 w-full"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>

                <span className="pl-2">
                  Send Reminder
                </span>
              </button>
            : null }

            {contribution.status === PaymentStatus.CANCELLED && contribution.type === ContributionTypes.BILL ?
              <button
                onClick={() => {
                  recreateBill(contribution)
                  setShowMenu(false)
                }}
                className="text-my-orange hover:underline text-left block py-3 w-full"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
                </svg>

                <span className="pl-2">
                  Recreate
                </span>
              </button>
            : null }

            <button
              onClick={() => {
                setBillToDelete(contribution)
                setShowMenu(false)
              }}
              className="text-my-orange hover:underline text-left block py-3 w-full"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>
              <span className="pl-2">
                Delete
              </span>
            </button>
          </>
        </ContextMenu>
      </div>
    </div>
  </div>
}

export default PendingContributionCard