import { Link, useLocation } from "react-router-dom"
import LinkButton from "../General/LinkButton"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { logEvent, setAnalyticsCollectionEnabled } from "firebase/analytics"
import { useAnalytics } from "reactfire"

const CookieBanner = () => {

  const {pathname} = useLocation()

  const analytics = useAnalytics()
  const [visible, setVisible] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean>(false)

  useEffect(() => {
    const enabled = Cookies.get("_ga")
    if (!enabled) setVisible(true)
    if (enabled) setEnabled(true)
  }, [])

  const enableCookies = (enabled: boolean) => {
    // Hide the banner
    setVisible(false)

    // Enable/disable analytics
    setEnabled(enabled)
  }

  useEffect(() => {
    console.log("Setting analytics to:", enabled)
    setAnalyticsCollectionEnabled(analytics, enabled)

    // Set the consent (if enabled)
    if (enabled) {
      // Need to send the page_view event as the initial event isn't sent
      logEvent(analytics, "page_view", {page_location: pathname})
    }
  })

  if (!visible) return null

  return (<>
    <div className="w-full fixed bottom-0 bg-my-grey p-4 grid grid-cols-12 gap-4 text-center items-center text-xs">
      <div className="col-span-12 sm:col-span-8">
        <p>
          This website uses cookies for analytics purposes. Please read our <Link to="/privacy" className="font-bold">Privacy Policy</Link> to understand more.
        </p>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <LinkButton type="button" clickHandler={() => enableCookies(true)}>Allow</LinkButton>
      </div>
      <div className="col-span-12 sm:col-span-1">
        <button onClick={() => enableCookies(false)}>Deny</button>
      </div>
    </div>
  </>)
}

export default CookieBanner