import Hero from "../General/Hero"

const Fees = () => {
  const headingClass = "text-center bg-my-olive text-white border border-my-olive text-xl uppercase p-3"
  const cellClass = "p-3 border border-my-grey"
  return <div>
    <Hero title="Our Fees" />
    <div className="max-w-screen-md mx-auto space-y-2 p-2">
      <p className="hidden">
        I feel like we need some form of blurb here to at least fill some space or even to tell people about how awesome we are.
      </p>
      <table className="text-left w-full">
        <thead>
          <tr>
            <th colSpan={2} scope="col" className={headingClass}>
              General Fees/Information
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`${cellClass} border-t-0`}>
              Account setup
            </td>
            <td className={`${cellClass} border-t-0`}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass}`}>
              Monthly subscription
            </td>
            <td className={`${cellClass}`}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass}`}>
              Create jars
            </td>
            <td className={`${cellClass}`}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass}`}>
              Withdraw funds
            </td>
            <td className={`${cellClass}`}>
              Free
            </td>
          </tr>
          <tr>
            <td className={`${cellClass} border-b-0`}>
              Time for withdrawn funds to arrive in your bank account
            </td>
            <td className={`${cellClass} border-b-0`}>
              Usually instant, but please allow up to 24hours for busy periods
            </td>
          </tr>
          <tr>
          <th colSpan={2} scope="col" className={headingClass}>
              Service/Transaction Fees
            </th>
          </tr>
          <tr>
            <td className={`${cellClass} border-t-0`}>
              Payments less than £2
            </td>
            <td className={`${cellClass} border-t-0`}>
              £0.15
            </td>
          </tr>
          <tr>
            <td className={`${cellClass}`}>
              Payments from £2 to £3000
            </td>
            <td className={`${cellClass}`}>
              1% + £0.20
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}

export default Fees