import { useEffect, useState } from "react";

const useDocumentTitle = (title: string = "") => {
  const [documentTitle, setDocumentTitle] = useState(title)

  useEffect(() => {
    let title = "Flossi Pay - Collecting money online made easy!"

    if (documentTitle.length > 0) title = `${documentTitle} - ${title}`

    document.title = title
  }, [documentTitle])

  return [documentTitle, setDocumentTitle] as const
}

export {useDocumentTitle}