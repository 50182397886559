import { confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";
import LinkButton from "../General/LinkButton";

interface Props {
  code: string;
}

type FormValues = {
  password: string;
  passwordConfirm: string;
}

// https://firebase.google.com/docs/auth/custom-email-handler

const ResetPassword: React.FC<Props> = ({code}) => {
  const auth = useAuth()
  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, setError, watch } = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: "all"
  });
  const password = useRef({});
  password.current = watch("password", "");

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true)
    if (!email) return
    try {
      await confirmPasswordReset(auth, code, data.password)
      await signInWithEmailAndPassword(auth, email, data.password)
      navigate("/my-account")
    } catch (error) {
      console.log(error)
      setError("passwordConfirm", {message: "Failed to change password"})
    }
    setIsLoading(false)
  }

  const [email, setEmail] = useState<string | false | null>(null)
  useEffect(() => {
    verifyPasswordResetCode(auth, code)
      .then((email) => {
        setEmail(email)
      }).catch((e) => {
        setEmail(false)
      })
  })

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="bg-white">
      <div className="header bg-hero-balloons bg-no-repeat bg-center bg-cover pt-14">
        <h1 className="text-3xl py-20 text-white font-beloved">Reset Password</h1>
      </div>

      <div className="text-left text-my-grey p-8 mx-auto max-w-md">
        {email === false ?
        <div className="p-2 bg-red-100 border-red-400 border-2 my-2 text-center">
          <h2 className="uppercase font-bold text-xl">Invalid code</h2>
          <p>The link used to reset password has expired. Please request a new one <Link to="/forgotten-password"className="text-blue underline">here</Link></p>
        </div>
        : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={!email}>
            <div className="mx-auto text-center">
              <p>Please enter a new password to reset it.</p>
              <div className="py-1">
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    {...register("password", {
                      validate: {
                        length: (v) => {return v.length > 7 ? true : "Password must be at least 8 characters long"},
                        containsNumber: (v) => {return /\d/.test(v) ? true : "Password must contain at least one number"},
                        containsSpecialChar: (v) => {return /\p{P}/u.test(v) ? true : "Password must contain at least one special character"},
                      }
                    })}
                    className="border p-2 w-full outline-my-orange"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="p-2 absolute right-0"
                  >
                    {showPassword ?
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      </svg>
                    }
                  </button>
                </div>
                {errors.password && errors.password.types ?
                  <ul className="list-disc text-left text-sm list-inside">
                    {Object.values(errors.password.types).map((e, i) => {
                      return <li className="text-red-600" key={i}>{e}</li>
                    })}
                  </ul>
                : null }
              </div>

              <div className="py-1 pb-2">
                <input
                  type="password"
                  placeholder="Confirm password"
                  {...register("passwordConfirm", {
                    required: "Please confirm your password",
                    validate: value =>
                      value === password.current || "The passwords do not match"
                  })}
                  className="border p-2 w-full"
                />
                {errors.passwordConfirm && <p className="text-red-600">{errors.passwordConfirm.message}</p>}
              </div>
              {/* <button
                type="submit"
                className="inline-block text-center uppercase border border-my-orange hover:border-my-olive border-solid bg-my-orange hover:bg-my-olive font-bold text-white w-full max-w-xs mx-auto py-4"
              > */}
              <LinkButton
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update Password"}
              </LinkButton>
              {/* </button> */}
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword