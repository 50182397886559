import { Outlet, useOutletContext } from "react-router-dom"
import { MyAccountProps } from "./MyAccount"
import { SubmitHandler, useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Loading from "../General/Loading"
import { useAuth } from "reactfire"
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth"
import LinkButton from "../General/LinkButton"

const MyDetails = () => {
  const {authUser, userInfo, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()
  setDocumentTitle("Manage your account")
  setSubTitle("")

  const auth = useAuth()

  const {
    register: regPw,
    handleSubmit: hsPw,
    formState: {
      errors: errorsPw
    },
    setError: setErrorPw,
    setFocus: setFocusPw
  } = useForm<{password: string}>()

  useEffect(() => {
    setFocusPw("password")
  }, [setFocusPw])

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
  const [isReauthenticated, setIsReauthenticated] = useState(false)
  const reauthenticateUser: SubmitHandler<{password: string}> = async (data) => {
    setIsAuthenticating(true)
    if (authUser.email === null) {
      auth.signOut();
      throw new Error("user email was null")
    }
    const cred = EmailAuthProvider.credential(
      authUser.email,
      data.password
    )
    try {
      await reauthenticateWithCredential(authUser, cred)
      setIsReauthenticated(true)
    } catch (e) {
      setErrorPw("password", {
        message: "Unable to reauthenticate. Please try again."
      })
      setIsReauthenticated(false)
    }
    setIsAuthenticating(false)
  }

  if (isAuthenticating) return <Loading />

  return (
    <div className="my-auto">
      {isReauthenticated === false?

      <div className="grid grid-cols-1 gap-4 p-2">
        <form onSubmit={hsPw(reauthenticateUser)}>
          <p className="text-center">To change your account details, please re-enter your password:</p>
          <div className="mx-auto max-w-xs">
            <input
              type="password"
              placeholder="Password"
              {...regPw(
                "password",
                {
                  required: "Please enter your password",
                }
              )}
              className="border p-2 block w-full my-2"
            />
            {errorsPw.password && <p className="text-red-600">{errorsPw.password.message}</p>}
            {/* <input type="submit" value="Confirm" className="inline-block text-center uppercase border border-my-orange hover:border-my-grey border-solid bg-my-orange hover:bg-white font-bold text-white hover:text-my-grey w-full max-w-xs mx-auto py-4 hover:cursor-pointer outline-my-orange" /> */}
            <LinkButton type="submit">Confirm</LinkButton>
          </div>
        </form>
      </div>
      :
        <Outlet context={{authUser, userInfo, setDocumentTitle, setSubTitle}} />
      }
    </div>
  )
}

export default MyDetails