import { useState } from "react";
import { BillTypes, ContributionTypes, Gift, PaymentStatus } from "../../FirestoreConverters"
import ContextMenu from "../General/ContextMenu";
import PaymentStatusIcon from "../General/PaymentStatusIcon";

type ContributionCardProps = {
  contribution: Gift;
  setBillToRepeat: (bill: Gift) => void;
}

const GeneralContributionCard = ({contribution, setBillToRepeat}: ContributionCardProps) => {

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const {
    giftAmount,
    message,
    type,
    cardholderLName,
    cardholderFName,
    giftedAt,
    status,
  } = contribution

  return <div className={`flex px-4 py-2 ${showMenu === true ? "bg-gray-100" : ""}`}>
    <div className="flex-initial w-7/12">

      {type === ContributionTypes.REDEEM ?
        <p>
          <span className="font-bold">You</span> withdrew
        </p>
      : null}

      {type === ContributionTypes.BILL ?
        "billType" in contribution && contribution.billType === BillTypes.INSTANT ?
          <p>
            Instant Bill
          </p>
        :
          <p>
            Bill for
            <span className="font-bold">
              {` ${cardholderFName} ${cardholderLName}`}
            </span>
          </p>
      : null}

      {type === ContributionTypes.GENERAL ?
        <p>
          <span className="font-bold">
            {cardholderFName} {cardholderLName}
          </span> paid
        </p>
      : null}
      <p className="text-xs">{giftedAt.toDate().toLocaleDateString()}</p>
      {message !== undefined && message.length > 0 ?
        <p className="text-sm md:text-md">
          <span className="font-bold">Ref:</span> {message}
        </p>
      : null}
    </div>
    <div className="flex-none w-3/12 text-right my-auto text-my-orange md:text-2xl font-bold">
      {type === ContributionTypes.REDEEM && giftAmount < 0 ?
        `- £${(giftAmount * -1).toFixed(2)}`
      :
        `£${giftAmount.toFixed(2)}`
      }
    </div>
    <div className="flex-none w-1/12 text-right my-auto">
      <PaymentStatusIcon status={status as PaymentStatus} />
    </div>
    <div className="flex-none w-1/12 relative my-auto text-right">
      {contribution.status === PaymentStatus.PAID && contribution.type === ContributionTypes.BILL && contribution.billType === BillTypes.EMAIL ?
        <ContextMenu showMenu={showMenu} setShowMenu={setShowMenu}>
          <button
            onClick={() => {
              setShowMenu(false)
              setBillToRepeat(contribution)
            }}
            className="text-my-orange hover:underline text-left block py-2 w-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
            </svg>

            <span className="pl-2">
              Repeat
            </span>
          </button>
        </ContextMenu>
      : null }
    </div>
  </div>
}
export default GeneralContributionCard