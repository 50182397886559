import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import Hero from "../General/Hero"

const Terms = () => {
  const pageTitle = "Terms & Conditions"
  useDocumentTitle(pageTitle)
  return (
    <div className="bg-white">
      <Hero
        title={pageTitle}
      />
      <div className="mx-auto max-w-screen-md text-my-grey p-8 text-sm text-justify">
        <p>Last updated: 30th September 2024</p>

        <div className="italic space-y-2 py-2">
          <p>The Consumer Rights Act 2015 says:</p>

          <p>you can ask us to repeat or fix a service if it's not carried out with reasonable care and skill, or get some money back if we can't fix it;</p>

          <p>if a price hasn't been agreed upfront, what you're asked to pay must be reasonable;</p>

          <p>if a time hasn't been agreed upfront, it must be carried out within a reasonable time.</p>

          <p>This is a summary of some of your key rights. For detailed information from Citizens Advice please visit <a href="www.citizensadvice.org.uk" target="_blank" className="underline text-my-orange">www.citizensadvice.org.uk</a> or call 0808 223 1133.</p>

          <p>The information above summarises some of your key rights. It is not intended to replace the contract below, which you should read carefully.</p>
        </div>

        <ol className="list-decimal pl-4 font-bold text-xl space-y-2 leading-10">
          <li>
            <h1 className="inline">Overview</h1>
            <ol className="list-[lower-alpha] text-sm font-normal">
              <li>
                These terms set out:
                <ul className="list-[lower-roman] list-inside leading-6">
                  <li>your legal rights and responsibilities;</li>
                  <li>our legal rights and responsibilities; and</li>
                  <li>certain key information required by law.</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Definitions/Types of User</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>"Flossi", "Flossi Pay", "We", "Our" or "Us" refers to Flossi Pay Limited.</li>
              <li>"User", "You" or "Your" refers to each collection owner, giftee, gifter, contributor or any other person that uses the website.</li>
              <li>"Collection owner" or "Payee" refers to the person who creates a collection page and will be receiving the monetary contribution.</li>
              <li>"Collection" refers to an accumulation of online monetary contributions. This may also be termed as 'pot' or 'jar'.</li>
              <li>"General Collection" refers to a collection used for general purposes other than gifting.</li>
              <li>"Gifting Collection" refers to a collection used for gifting purposes only.</li>
              <li>"Contributor" or "Payer" refers to the person giving the money. Contributors will be invited to the collection contribution page (by the collection owner) to make an online monetary contribution/payment.</li>
              <li>A "Collection" page (created by the collection owner) acts as the landing page for the invited contributors to make an online monetary contribution.</li>
              <li>"Contribution" or "Payment" refers to money only. Flossi Pay is an online monetary gifting and general collection service only.</li>
              <li>Collection "close date" or "end date" refers to the final day that contributions can be made to a collection. This date is to be defined by the collection owner. Gifting collections require an end date. General collections can either be assigned an end date or set to as 'open ended' (i.e. no end date).</li>
              <li>"Payment gateway" or "Payment processor" is a third party that will manage receipt of, or payment of funds used for the contributed money.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Information About Flossi Pay</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>www.flossipay.com is a site operated by Flossi Pay Limited. We are a private limited company incorporated in England and Wales under company number 14130805.</li>
              <li>Our registered office is at: Riverside Offices, Second Floor, 26 St. Georges Quay, Lancaster, LA1 1RD</li>
              <li>Flossi Pay is an online monetary gifting and general collection service.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Accessing Our Website</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>Access to our website (www.flossipay.com) is permitted on a temporary basis. We reserve the right to amend the information at any time.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Collections</h1>
            <ol className="list-none list-outside text-sm font-normal leading-6 space-y-2">
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">5.1. Collection Owner Responsibility</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>The collection owner is responsible for the content of their collection page. The collection page must not contain any unlawful or offensive material. We regularly review collection page content and we reserve the right to cancel your collection page, account and access to the site at any time if we believe this includes inappropriate content.</li>
                  <li>The content on any collection page is the sole responsibility of the collection owner. Flossi Pay will not be liable for the content on any collection page, or any misrepresentation arising out of the collection.</li>
                </ol>
              </li>
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">5.2. Collection Process</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>Account Creation - Collection owners will be required to create a personal Flossi Pay account. This is required to allow the collection owner access to and manage their collection page throughout the live duration. Flossi Pay will complete a UK identification verification and anti-money laundering check on all collection owners prior to their first withdrawal, and again at regular intervals following. We reserve the right to cancel your account if we have reason to believe your use of the site is not legitimate.</li>
                  <li>Collection Creation - Once a collection is created (by the collection owner) it will be 'live'. This allows contributors to access the collection contribution page via a QR code/website link and make a monetary contribution (online payment).</li>
                  <li>Collection Close Date - The close/end date of a collection will be entered by the collection owner during creation. Contributors will be able to contribute to a collection from the date of collection creation until 11:59pm on the defined collection end date. Gifting collections require an end date. General collections can either be assigned an end date or set to be 'open ended' (i.e. no end date). The collection will then be located within the 'past collections' list of the 'my collections' section.</li>
                  <li>Editing a Collection Page - A collection owner can edit their collection page by accessing the specific collection and selecting the 'edit' option.</li>
                  <li>Cancelling a Collection - A collection can be cancelled by the collection owner by contacting Flossi Pay via email at support@flossipay.com. See Section 6.4 for further information on this.</li>
                  <li>Collection Withdrawal/Redeem - Once the collection has closed, the contributions can be redeemed. The total contributions for such collection will be collated and sent to the occasion owners bank account subject to the payment service processes and requirements. See Section 6.3 for further information on this. Flossi Pay apply a 6month deadline from the date that the collection is closed for the redeem process to be completed. See Section 15 for further information on this.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Contributions</h1>
            <ol className="list-none text-sm font-normal leading-6 space-y-2">
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">6.1. Contributor Responsibility</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>The contributor is responsible for the content they include within the contribution message section. The contribution messages must not contain any unlawful or offensive material. We regularly review message content, and we reserve the right to cancel your contribution at any time if we believe this includes inappropriate content. Any cancelled contribution will be refunded. In the event of such refund, the full contribution amount will be refunded excluding any initial transaction fee and refund processing fees.</li>
                </ol>
              </li>
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">6.2. Payments</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>Contribution payments will be made by contributors via our payment gateway provider Cashflows Europe Limited. They may have separate terms and conditions which will run in conjunction with these terms. Should there be any conflict between the third party terms and our terms, our terms shall take precedence.</li>
                  <li>Flossi Pay does not accept payments by non-UK or corporate debit or credit cards, cash or cheques.</li>
                </ol>
              </li>
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">6.3. Withdrawal/Redeem</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>All withdrawals of collection contributions will be completed by our payment service partner Acquired Limited. They may have separate terms and conditions which will run in conjunction with these terms. Should there be any conflict between the third party terms and our terms, ours terms shall take precedence.</li>
                  <li>Collection contributions can only be withdrawn to a bank account registered in the name of the Flossi account owner.</li>
                  <li>It is the responsibility of the collection owner to ensure that they correctly enter their bank account information for payment.</li>
                </ol>
              </li>
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">6.4. Collection Cancellation</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>If a collection is cancelled by the collection owner or Flossi Pay, all contributions for that collection will be refunded to the contributors. In the event of such refund, the full gift amount will be refunded excluding any initial transaction fee and refund processing fees.</li>
                </ol>
              </li>
              <li className="font-semibold -ml-4">
                <h2 className="-ml-3">6.5. Refund Process</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>If for any reason a contributor requires a refund for a contribution payment they can contact support@flossipay.com to request this. Such refunds will be processed at the discretion of Flossi Pay except in circumstances whereby the collection owner has initiated the collection cancellation process.</li>
                  <li>In the event of such refund, the full contributed amount will be refunded excluding any initial transaction fee and refund processing fees.</li>
                  <li>Under no circumstances will Flossi Pay be able to process a refund for any contribution payments after the collection has been redeemed by the collection owner.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Fees/Charges</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>There are no charges to create a Flossi Pay account.</li>
              <li>There are no charges to a collection owner to create a collection.</li>
              <li>A transaction fee will be charged for every contribution. For gifting collections, the transaction fee will be added to the contribution amount and will be paid by the contributor. For general collections, the collection owners can select if they, or the contributor will pay the transaction fee. The transaction fee amount will be clearly communicated to the contributor at the contribution stage.</li>
              <li>There are no charges to withdraw/redeem money from a collection.</li>
              <li>We will do all that we reasonably can to ensure that all of the information you give us when paying for the services is secure by using an encrypted secure payment mechanism. However, in the absence of negligence on our part, any failure by us to comply with these terms or our Privacy Policy or breach by us of our duties under applicable laws, we will not be legally responsible to you for any loss that you may suffer if a third party gains unauthorised access to any information that you give us.</li>
              <li>Flossi Pay retains the right to amend any transaction fees at any time, at their sole discretion, and do not need to notify the collection owners of any such changes.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Service Complaints</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>If you are unhappy with the service Flossi Pay provides or would like to provide feedback, you can contact support@flossipay.com, or by post to our registered address.</li>
              <li>Our carrying out of the services might be affected by events beyond our reasonable control. If so, there might be a delay before we can start or restart the services, having made reasonable efforts to limit the effect of any of those events and having kept you informed of the circumstances, but we will try to start or restart the services as soon as those events have been fixed.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Privacy Policy</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>Your privacy and personal information are important to us. Any personal information that you provide to us will be dealt with in line with our Privacy Policy, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities if you have a query or complaint about the use of your personal information. This includes personal information including names, addresses and contact details provided to us by general collection owners which relates to their customers and may be provided for the purposes of billing.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Intellectual Property</h1>
            <ol className="list-none -ml-4 text-sm font-bold leading-6 space-y-2">
              <li>
                <h2 className="-ml-3">10.1. Trademarks and IP rights</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>All intellectual property rights in the Site and the material published on it are owned or licenced by us. These works are safeguarded by trademark laws and agreements all throughout the world. All of these rights are retained.</li>
                  <li>Our registered trademarks are "Flossi and "Flossi Pay" and you undertake not to exhibit or use these in any way without our prior written consent. Flossi, Flossi Pay, www.flossipay.com, the Flossi Pay logo, and any other provider names that we may display on the Site occasionally may not be used in relation with any good or service that is not Flossi Pay's, in any way that is likely to mislead, or in any way that disparages or discredits Flossi Pay.</li>
                  <li>Other trademarks, service marks, or logos appearing on the Site, including those of non-profits, third-party suppliers, or other institutions, are the property of the owner and are likely to be registered trademarks with usage limitations. They must not be utilised without our full authorization and, where relevant, the trademark owner's approval.</li>
                </ol>
              </li>
              <li>
                <h2 className="-ml-3">10.2. Licenses</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>For the duration of this contract, we offer you a non-exclusive, non-transferable, royalty-free permission to use our intellectual property rights in order to get the benefit of the services.</li>
                  <li>You grant us a non-exclusive, sub-licensable, royalty-free licence to utilise your intellectual property rights in order for us to offer our services to you for the duration of this contract.</li>
                </ol>
              </li>
              <li>
                <h2 className="-ml-3">10.3. Copyright</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>We and other third-party providers control all material on the Site, which is protected by intellectual property and proprietary rights and laws. You may reproduce content for your own private, non-commercial use as long as you do not modify it or remove any copyright, trademark, or other property notice, and your usage agrees with any requests you may get from anyone with rights in that content. Other than as expressly allowed by us and, where appropriate, the copyright holder, no other use of the Site's material is permitted.</li>
                  <li>If you put any content on the Site, you must guarantee that you hold the relevant copyright or intellectual property rights to that content or that you have received complete permission from the IP holder to use that content on the Site. We reserve the right, at our absolute discretion and without warning, to remove any content, including but not limited to photos, photographs, or copies from the Site if their copyright status is in dispute.</li>
                  <li>You may use and access the Site only to the extent necessary for using our services in line with these terms and conditions and for the purposes for which we make them accessible. You are not permitted to delete any copyright, trademark, or other intellectual property indications found in anything downloaded from the Site.</li>
                  <li>Without our prior explicit written permission, you may not use any algorithm, worm, scraper, or other automated methods to access the Site for any reason.</li>
                </ol>
              </li>
              <li>
                <h2 className="-ml-3">10.4. Disclaimer</h2>
                <ol className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>We make no claims or guarantees that the material or information on the Site is correct, full, up to date, or does not violate the rights of any outside party.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Site Content and Service Access</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>We may occasionally offer you Site access via an alternate method of access, such as Microsoft Teams, if you or another User requests it.</li>
              <li>Where a third party provides this alternative method of access, your use of that third party's system and any extra services supplied by them will be subjected to their terms and conditions for the use of their system.</li>
              <li>The Site may contain information and materials posted by other Site Users. We have not verified or authorised these information or contents. The views, perspectives and opinions expressed and outlined by other Users on our webpage do not reflect or correspond with our views, perspectives and opinions.</li>
              <li>We will always make every effort to keep the Site available 24 hours a day, seven days a week. However, we shall not be responsible if the Site is inaccessible at any time or for any amount of time for any reason.</li>
              <li>You may only use the Site for legitimate reasons. You hereby concur, accept and understand not to utilise the Site in any of the following manners/ways:
                <ul className="list-disc pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>in order to damage or seek to harm youth in any way.</li>
                  <li>to frighten, offend, or humiliate somebody.</li>
                  <li>to send or arrange for the transmission of any unwanted or unauthorised marketing or commercial material, or any other kind of comparable solicitation.</li>
                  <li>to intentionally transfer data, send or upload information containing viruses, Trojan horses, worms, time bombs, keystroke loggers, spyware, advertising, or any other harmful programmes or related computer code designed to impair the functioning and performance of any and/or all computer software(s) or hardware(s).</li>
                  <li>to collect any personal information for business reasons, such as account names, email addresses, or any similar information.</li>
                </ul>
              </li>
              <li>You also agree:
                <ul className="list-disc pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>that you are at least 18 years old, that you will give correct information when establishing a collection or registering for our services, and that you are entirely responsible for your user access credentials and any action that happens while logged in to or using the Site.</li>
                  <li>not to replicate, duplicate, copy, or resell any element of the Site unless expressly permitted by these terms and conditions.</li>
                  <li>not to unlawfully use, intrude with, harm, or obstruct any portion of the webpage, the hardware or system on which it is kept, the software used to run the site, or any hardware, network, or software that is the property of, or utilised by, a third party.</li>
                  <li>not to utilise the site's communication services or any connections formed on the site for commercial recruiting reasons.</li>
                  <li>must follow any instructions we post on the Site or otherwise communicate to you regarding the use of the services.</li>
                </ul>
              </li>
              <li>We actively review all collections set up on the Site and thus reserve the right, at our judgement and without warning, to pause or cancel a collection, or to delete or modify any content posted, irrespective of whether it is insulting, outrageous, racist, defamatory, misrepresentative, deceitful, or otherwise inappropriate in the opinion of any third party.</li>
              <li>We retain the right to terminate your access to the Site and remove any collections without notice if you violate any of the above rules, or if we otherwise believe it is essential to manage risks to either us or anybody else.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Third Party Links</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>For your convenience, we may include links to other websites or information on occasion. We do not endorse the content of those websites and do not guarantee their accessibility or service. We shall not be responsible in any way for any damage or loss you may incur as a result of utilising those websites. You access connected third-party websites entirely at your own risk.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Liability</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>To the largest extent permissible by law, we disclaim any express or implied representations, warranties, conditions, and agreements.</li>
              <li>If we refuse to adhere with these terms and conditions, we shall be liable solely for direct losses or damage you incur as a reasonably foreseeable result of our significant breach of these terms and conditions or our gross negligence. We shall not be held liable for any unforeseeable loss or damage. Damage or injury is predictable in the event that it is either obvious that it will happen or if we both knew it may happen at the point the agreement was executed, such as if you addressed it with us.</li>
              <li>We only provide Gifting Collections for personal and household use. You agree not to use the Gifting Collections for commercial or business reasons, and we will have no obligation to you for any loss of profit, business disruption, or business opportunity.</li>
              <li>Flossi does not accept any liability in respect of any tax that may be due in respect of any of the Collections. It remains the responsibility of the Site User or the Collection Owner to determine any tax liability and account to HMRC accordingly.</li>
              <li>None of these terms and conditions shall exclude or restrict our responsibility for:
                <ul className="list-disc pl-4 text-sm font-normal">
                  <li>death or personal injury caused by negligence</li>
                  <li>fraud</li>
                  <li>misrepresentation as to a fundamental issue</li>
                  <li>or any other obligation that the relevant legislation does not prohibit or restrict.</li>
                </ul>
              </li>
              <li>General Collections can be used by businesses in relation to payments for goods and services they provide to their customers. Flossi does not accept liability for any disputes between general collection owners and third parties and any disputes between the general collection owners and their customers are not the responsibility of Flossi, including but not limited to disputes relating to billing, refunds or debt recovery.</li>
              <li>You shall be liable to us for any claims, damages, losses, and costs (including reasonable legal expenses) resulting from any violation of these terms and conditions by you, or your use of the Site, or the use of the Site by anyone who uses your registration details.</li>
            </ol>
          </li>
          <li>
            <h1 className="inline">Termination and Suspension</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>You may stop or prevent yourself from using the Site or our services at any point and moment in time without liability or consequences whatsoever. These terms and conditions shall remain in effect for any previous usage of the Site and our services.</li>
              <li>If you refuse to adhere with these terms and conditions, we may, in our sole discretion, suspend or terminate your access to all or part of the Site and our services immediately and without notice.</li>
              <li>We retain the right, with or without notice, to change, stop, or cancel all or any portion of the Site or our services at any time.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">Collection Expiration</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>If a collection owner does not redeem the specific collection money after the collection deadline/closure date has passed, we shall make reasonable efforts to give you reminders regarding the collection worth.</li>
              <li>If 6 months have gone from the closing date and the collection has not been redeemed, we will presume that you no longer intend to utilise the collection and it will expire. At this point, the contributions will be automatically refunded to the contributors. In the event of such refund, the full contribution amount(s) will be refunded excluding any initial transaction fee and refund processing fees.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">Competitions & Promotions</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>We may hold promotions and competitions from time to time. Before participating in a promotion or competition, you should read the terms and conditions.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">Faulty Services</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>Your legal rights under the Consumer Rights Act 2015 (also known as 'statutory rights') are set out at the top of this page. They are a summary of some of your key rights. For more detailed information on your rights and what you should expect from us, please:
                <ul className="list-disc pl-4 text-sm font-normal">
                  <li>contact us using the contact details at the clause 8; or</li>
                  <li>visit the Citizens Advice website www.citizensadvice.org.uk or call 0808 223 1133.</li>
                </ul>
              </li>
              <li>Nothing in this contract affects your legal rights under the Consumer Rights Act 2015 (also known as 'statutory rights'). You may also have other rights in law.</li>
              <li>If the services we have provided to you are faulty, please contact us using the contact details provided above.</li>
            </ol>
          </li>

          <li>
            <h1 className="inline">Disputes</h1>
            <ol className="list-[lower-alpha] text-sm font-normal leading-6 space-y-2">
              <li>We will try to resolve any disputes with you quickly and efficiently. If you are unhappy with the services we have provided or any other matter, please contact us as soon as possible using the contact details set out at the top of this page.</li>
              <li>If a dispute cannot be resolved in by you contacting us as per clause 8 above, or you are unhappy with the outcome, you may want to use alternative dispute resolution (ADR). ADR is a process for resolving disputes between you and us that does not involve going to court.</li>
              <li>If you do not wish to use ADR or are unhappy with the outcome of ADR, you can still bring court proceedings.</li>
              <li>The laws of England and Wales apply to this contract, although if you are resident elsewhere you will retain the benefit of any mandatory protections given to you by the laws of that country.</li>
              <li>Any disputes will be subject to the non-exclusive jurisdiction of the courts of England and Wales. This means that you can choose whether to bring a claim in the courts of England and Wales or in the courts of another part of the UK in which you live.</li>
            </ol>
          </li>

          <li className="font-bold">
            <h1 className="inline">General</h1>
            <ol className="list-none -ml-4 text-sm leading-6 space-y-2">
              <li>
                <h2 className="-ml-3">19.1. Assignment</h2>
                <ul className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>We may assign our rights and duties under these terms to another organisation.</li>
                  <li>Only if we agree in writing may you transfer your rights or responsibilities under these terms and conditions to another party.</li>
                </ul>
              </li>

              <li>
                <h2 className="-ml-3">19.2. Third party rights</h2>
                <ul className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>This agreement is between you and us. No one else has the authority to impose any of these terms and conditions.</li>
                </ul>
              </li>

              <li>
                <h2 className="-ml-3">19.3. Severance</h2>
                <ul className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>If any of these terms and conditions are deemed to be invalid, the other portions will remain fully enforceable.</li>
                </ul>
              </li>

              <li>
                <h2 className="-ml-3">19.4. No Waiver</h2>
                <ul className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>No waiver by us of any of the terms and conditions stated herein shall be regarded to be ongoing or a waiver of any other term.</li>
                </ul>
              </li>

              <li>
                <h2 className="-ml-3">19.5. Governing Laws and Jurisdiction</h2>
                <ul className="list-[lower-alpha] pl-4 text-sm font-normal leading-6 space-y-2">
                  <li>These terms and conditions shall be understood and interpreted in accordance with the law of England and Wales, and they will be resolved in a court in England and Wales.</li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  )
  }

export default Terms