import {
  useOutletContext, useSearchParams
} from "react-router-dom";
import { Event, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters";
import { useEffect, useState } from "react";
import { DocumentReference, DocumentSnapshot, FirestoreError, doc, onSnapshot } from "firebase/firestore";
import { useAnalytics, useFirestore } from "reactfire";
import Loading from "../General/Loading";
import { logEvent } from "firebase/analytics";
import { User } from "firebase/auth";
import LinkButton from "../General/LinkButton";

const PaymentConfirmation = () => {
  const firestore = useFirestore()
  const analytics = useAnalytics()
  const {eventInfo} = useOutletContext<{eventInfo: Event, userInfo: User}>()
  const [searchParams] = useSearchParams()
  const [giftData, setGiftData] = useState<Gift | undefined | false>(undefined)

  useEffect(() => {
    const streamGift = (giftRef: DocumentReference<Gift>, snapshotHandler: (snapshot: DocumentSnapshot<Gift>) => void, errorHandler: (e: FirestoreError) => void ) => {
      console.log("Streaming gift...")
      return onSnapshot(giftRef, snapshotHandler, errorHandler)
    }

    const ordNum = searchParams.get("ordernumber")
    if (ordNum === null) throw new Error("No order number found")

    const [eventId, giftId] = ordNum.split("/")

    if (eventId === null || giftId === null || eventInfo.id !== eventId) {
      throw new Error("Unable to confirm payment")
    }

    const ref = doc(firestore, `events/${eventInfo.id}/gifts/${giftId}`)
      .withConverter(FirestoreGiftConverter)

    const unsub = streamGift(
      ref,
      (snapshot) => {
        console.log("got new snapshot", {snapshot})
        setGiftData(snapshot.data())
      },
      (e) => {
        setGiftData(false)
        console.log(e)
      })
    return unsub
  }, [eventInfo, setGiftData, firestore, searchParams])

  if (giftData === undefined || giftData === false || giftData.status !== PaymentStatus.PAID) {
    return <div>
      <h1 className="text-xl text-my-grey p-4 font-bold uppercase">Processing payment</h1>
      <p>Please wait while your payment is processed. Once complete, you will see confirmation.</p>
      <Loading />
    </div>
  }

  logEvent(analytics, "purchase", {
    transaction_id: giftData.paymentRef,
    value: giftData.totalAmount,
    items: [
      {
        item_name: "Gift Amount",
        price: giftData.giftAmount
      },
      {
        item_name: "Fee Amount",
        price: giftData.feeAmount
      }
    ]
  })

  return (<>
    <div>
      <div className="py-6">
        <img alt="Jar with coins in" src="/img/event-icon.png" className="mx-auto w-full max-w-96"/>
      </div>
      <h1 className="uppercase font-poppins text-3xl">Thank you for your payment!</h1>
      <p className="p-2 break-words">Your &pound;{giftData.totalAmount.toFixed(2)} payment has been successfully processed.</p>
      <p>You will shortly receive an email confirming your payment.</p>
    </div>
    <div className="mx-auto my-5 border-t">
      <p className="py-5 font-bold uppercase">Want to create your own collection?</p>
      <LinkButton type="link" url={"/create-jar"}>Create a jar</LinkButton>
    </div>
  </>)
}

export default PaymentConfirmation