import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "reactfire";
import Hero from "../General/Hero";
import LinkButton from "../General/LinkButton";

type FormValues = {
  email: string;
}

const ResetPassword = () => {
  const auth = useAuth()
  const { register, handleSubmit, formState: { errors }, setError } = useForm<FormValues>();
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)

  const onSubmit: SubmitHandler<FormValues> = async data => {
    setIsSending(true)
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        setEmailSent(true)
        setIsSending(false)
      })
      .catch((error) => {
        //const errorCode = error.code;
        const errorMessage: string = error.message;
        setError("email", {type: "string", message: errorMessage}, {shouldFocus: true})
        setIsSending(false)
        // ..
      });
  }

  return (
    <div className="bg-white">
      <Hero
        title="Forgotten Password"
      />

      {emailSent ? <div className="border-4 m-4 mx-auto text-my-grey max-w-lg p-8">
        A password reset email has been sent to the address you entered,
        please follow the instructions within that email.
      </div> : null}

      <div className="text-left text-my-grey p-8 mx-auto max-w-md flex flex-grow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto text-center">
            <p className="font-bold uppercase">Please enter your email address:</p>

            <input
              type="email"
              placeholder="Email"
              {...register("email", {required: "Please enter your email", pattern: /^\S+@\S+$/i})}
              className="border p-2 w-full my-4 text-lef outline-my-orange"
            />
            {errors.email && <p className="text-red-600">{errors.email.message}</p>}
            {/* <input type="submit" value="Send Reset Email" className="inline-block text-center uppercase border border-my-olive hover:border-my-orange border-solid bg-my-olive hover:bg-my-orange font-bold text-white w-full max-w-xs mx-auto py-4" /> */}
            <LinkButton type="submit" backgroundColour="my-olive">{isSending? "Sending..." : "Send Reset Email"}</LinkButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword