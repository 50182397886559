import { useSearchParams } from "react-router-dom"
import NotFound from "../General/NotFound"
import ResetPassword from "../User/ResetPassword"
import VerifyEmail from "../User/VerifyEmail"

const HandleUserAction = () => {

  const [searchParams] = useSearchParams()

  const mode = searchParams.get('mode')
  const actionCode = searchParams.get('oobCode')

  // if anything is undefined, we stop
  if (!mode || !actionCode) return <NotFound />

  switch (mode) {
    case 'resetPassword':
      // Display reset password handler and UI.
      return <ResetPassword code={actionCode} />
    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail code={actionCode} />
    case 'recoverEmail':
    default:
      // We're not doing email recovery
      // or, there's an invalid mode
      return <NotFound />
  }
}

export default HandleUserAction