import { applyActionCode, sendEmailVerification } from "firebase/auth";
import { useState } from "react";
import { useAuth } from "reactfire";
import LinkButton from "../General/LinkButton";
import Hero from "../General/Hero";

interface Props {
  code: string;
}

const VerifyEmail: React.FC<Props> = ({code}) => {
  /**
   * undefined = not yet done
   * true = complete success
   * false = complete error
   */
  const [verified, setVerified] = useState<boolean | undefined>(undefined)

  const auth = useAuth()

  useState(() => {
    applyActionCode(auth, code)
    .then(() => {
      setVerified(true)
    })
    .catch((e) => {
      setVerified(false)
    })
  })

  const sendVerificationEmail = () => {
    if (auth.currentUser == null) return
    sendEmailVerification(auth.currentUser)
  }

  return (
    <div className="bg-white">
      <Hero
        title="Email Verification"
      />

      <div className="mx-auto text-center text-my-grey p-8 max-w-xl">
        {verified === true ? <>
          <h2 className="text-2xl font-bold uppercase">Thank you!</h2>
          <p>Your email address has been verified.</p>
          <LinkButton type="link" url="/my-account">Go to my account</LinkButton>
        </> : <>
          {verified === false ?
            <>
              <h2 className="text-2xl font-bold uppercase">Verification failed</h2>
              <p>We're sorry, but we were unable to verify your email address.</p>
              <p>You can request a new verification email by clicking the button below.</p>
              <LinkButton type="button" clickHandler={() => {sendVerificationEmail()}}>
                send verification email
              </LinkButton>
            </>
            :
            <p>Please wait while your email address is verified</p>
          }
        </> }
      </div>
    </div>
  )
}

export default VerifyEmail