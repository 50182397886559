import { SubmitHandler, useForm } from "react-hook-form"
import { BillContribution, BillTypes, ContributionTypes, Event, Gift, PaymentStatus, User } from "../../FirestoreConverters"
import { useEffect, useState } from "react"
import LinkButton from "../General/LinkButton"
import { Timestamp } from "firebase/firestore"
import { calculateGiftAmounts, saveGift } from "../../services/Gift"
import { useAnalytics, useFirestore, useFunctions } from "reactfire"
import { logEvent } from "firebase/analytics"
import { httpsCallable } from "firebase/functions"

interface CreateTxnFormProps {
  billToRepeat?: Gift;
  event: Event;
  userInfo: User;
  closeFunction: (close: boolean) => void;
  setTitle: (title: string) => void;
}

interface CreateTxnFormData {
  cardholderFName: string;
  cardholderLName: string;
  email: string;
  amount: number;
  reference: string;
}

const CreateTxnForm = ({ billToRepeat, event, userInfo, closeFunction, setTitle }: CreateTxnFormProps) => {
  setTitle("Create Bill")
  const firestore = useFirestore()
  const analytics = useAnalytics()
  const functions = useFunctions()
  const sendBillEmail = httpsCallable(functions, "sendBillEmail")

  const {
    register,
    formState: {
      errors
    },
    handleSubmit,
    setError,
    setFocus,
    watch
  } = useForm<CreateTxnFormData>({
    reValidateMode: "onChange",
    mode: "all"
  })

  useEffect(() => {
    setFocus("cardholderFName")
  }, [setFocus])

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<CreateTxnFormData> = async ({
    cardholderFName,
    cardholderLName,
    email,
    amount,
    reference
  }) => {
    setIsLoading(true)
    // setErrorMessage(false)

    const {
      feeAmount,
      giftAmount,
      totalAmount
    } = calculateGiftAmounts(event, amount)

    const gift: BillContribution = {
      message: reference.trim(),
      giftAmount: giftAmount,
      feeAmount: feeAmount,
      totalAmount: totalAmount,
      email: email.trim(),
      cardholderFName: cardholderFName.trim(),
      cardholderLName: cardholderLName.trim(),
      giftedAt: new Timestamp(Date.now() / 1000, 0),
      uid: userInfo.id,
      type: ContributionTypes.BILL,
      billType: BillTypes.EMAIL,
      status: PaymentStatus.PENDING
    }
    /**
     * 1. Construct Gift
     * 2. Save Gift in Firestore
     * 4. Update gift with request
     * 3. Create payment job in cashflows
     * 4. Update gift with payment response
     * 5. Popup/redirect hosted payment page
     */

    try {
      const giftDoc = await saveGift(firestore, functions, event, gift)
      const giftData = giftDoc.data()

      if (
        giftData !== undefined &&
        giftData.type === ContributionTypes.BILL &&
        giftData.billType === BillTypes.EMAIL
      ) {
        try {
          await sendBillEmail({
            jarOwnerFirstName: userInfo.firstName,
            jarOwnerLastName: userInfo.lastName,
            firstName: giftData.cardholderFName,
            email: giftData.email,
            billUrl: `/contribute-to/${event.id}/bill/${giftData.id}`,
            billAmount: giftData.giftAmount,
            feeAmount: giftData.feeAmount,
            totalAmount: giftData.totalAmount,
            reference: giftData.message,
            feeIncluded: event.feeIncluded
          })
        } catch (e) {
          // Don't do anything if we fail to send the email right now
        }
      }
      closeFunction(false)
    } catch (e: any) {
      console.log(e)
      setError("root", { message: "Error, please try again" })
      logEvent(analytics, "exception", {
        description: e.toString()
      })
    }
    setIsLoading(false)
  }

  const [giftAmount, setGiftAmount] = useState(0)
  const [feeAmount, setFeeAmount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const amountField = watch("amount")
  useEffect(() => {
    if (amountField === undefined || isNaN(amountField)) {
      setFeeAmount(0)
      setGiftAmount(0)
      setTotalAmount(0)
      return
    }
    const {
      feeAmount,
      giftAmount,
      totalAmount
    } = calculateGiftAmounts(event, amountField)
    setFeeAmount(feeAmount)
    setGiftAmount(giftAmount)
    setTotalAmount(totalAmount)
  }, [amountField, event, setFeeAmount, setGiftAmount, setTotalAmount])

  return  <form onSubmit={handleSubmit(onSubmit)}>
    <fieldset disabled={isLoading}>
      <div className="p-4 md:pt-5 grid grid-cols-1 gap-4">
        <div className="text-left">
          <label className="block w-full font-bold uppercase">
            Bill Payer Name:
          </label>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <input
              type="text"
              placeholder="First"
              {
              ...register(
                "cardholderFName",
                {
                  required: "Please enter the bill payer's first name",
                  maxLength: 80,
                  value: billToRepeat !== undefined ? billToRepeat.cardholderFName : ""
                },
              )
              }
              className="border p-2 outline-my-orange capitalize order-1"
            />
            <input
              type="text"
              placeholder="Last"
              {
              ...register(
                "cardholderLName",
                {
                  required: "Please enter the bill payer's last name",
                  maxLength: 80,
                  value: billToRepeat !== undefined ? billToRepeat.cardholderLName : ""
                }
              )
              }
              className="border p-2 outline-my-orange capitalize order-3 sm:order-2"
            />
            <p className="font-poppins text-red-600 text-center order-2 sm:order-3">{errors.cardholderFName && errors.cardholderFName.message}</p>
            <p className="font-poppins text-red-600 text-center order-4">{errors.cardholderLName && errors.cardholderLName.message}</p>
          </div>
        </div>
        <div className="text-left">
          <label className="block w-full font-bold uppercase">
            Bill Payer email address:
          </label>
          <input
            type="email"
            placeholder="Email"
            {
            ...register(
              "email",
              {
                required: "Please enter the bill payer's email",
                pattern: {
                  value: /^\S+@\S+/i,
                  message: "Please enter a valid email"
                },
                value: billToRepeat !== undefined ? billToRepeat.email : ""
              }
            )
            }
            className="border p-2 w-full outline-my-orange"
          />
          <p className="font-poppins text-red-600 text-center">{errors.email && errors.email.message}</p>
        </div>

        <div className="text-left">
          <label className="block w-full font-bold uppercase">
            Bill Amount:
          </label>
          <div className="flex">
            <input
              type="text"
              value="£"
              className="border p-2 flex-0 w-10 font-bold text-center"
              disabled
            />
            <input
              type="number"
              step="0.01"
              min={0}
              max={event.organiser.id === "nBJ23V5SnqU2duBEqvJsVdz8v9V2" ? 50000 : 3000}
              {
              ...register(
                "amount",
                {
                  required: "Please enter an amount",
                  valueAsNumber: true,
                  validate: {
                    format: (v) => {
                      if (/^[0-9]\d{0,5}(\.\d{2})?$/g.test(v.toFixed(2))) return true
                      return "Invalid amount specified"
                    }
                  },
                  max: {
                    value: event.organiser.id === "nBJ23V5SnqU2duBEqvJsVdz8v9V2" ? 50000 : 3000,
                    message: "The maximum amount is £3,000"
                  },
                  min: {
                    value: event.feeIncluded === true ? 0.2 : 0.01,
                    message: event.feeIncluded === true ? "The minimum value is 20p" : "Please enter a value greater than 0"
                  },
                  setValueAs: (v) => {
                    const d = parseFloat(v).toFixed(2)
                    return d
                  },
                  value: billToRepeat !== undefined ? billToRepeat.giftAmount : undefined
                }
              )
              }
              className="border p-2 flex-grow outline-my-orange"
              pattern="\d{1-4}(\.d{2})"
              inputMode="decimal"
            />
          </div>
          <p className="font-poppins text-red-600 text-center">{errors.amount && errors.amount.message}</p>
        </div>

        <div className="text-left">
          <label className="block w-full font-bold uppercase">
            Reference:
          </label>
          <input
            type="text"
            placeholder="Ref."
            {
            ...register(
              "reference",
              {
                required: "Please enter a reference",
                value: billToRepeat !== undefined ? billToRepeat.message : ""
              }
            )
            }
            className="border p-2 w-full outline-my-orange"
          />
          <p className="font-poppins text-red-600 text-center">{errors.reference && errors.reference.message}</p>
        </div>
        <p className="font-poppins text-red-600 text-center">{errors.root && errors.root.message}</p>
      </div>

      {/* <!-- Modal fee section --> */}
      <div className="py-2 md:py-5 border-t border-gray-200 rounded-b text-center">
        <ul>
          <li>You Get: &pound;{(giftAmount).toFixed(2)}</li>
          <li>Transaction Fee: &pound;{feeAmount.toFixed(2)}</li>
          <li><b>Bill Amount: &pound;{totalAmount.toFixed(2)}</b></li>
        </ul>
      </div>

      {/* <!-- Modal footer --> */}
      <div className="pt-4 md:pt-5 border-t border-gray-200 rounded-b text-center">
        <LinkButton
          type="submit"
          disabled={isLoading}
        >
        {isLoading === true ?
          <>
            <div className="relative">Processing
              <svg className="animate-spin h-6 w-6 absolute left-8 inline" viewBox="0 0 24 24">
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </>
          : <>
            Send bill
          </>}
        </LinkButton>
      </div>
    </fieldset>
  </form>
}

export default CreateTxnForm