import { User } from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  updateDoc
} from 'firebase/firestore';
import { useEffect, useState } from "react";
import {
  SubmitHandler,
  useForm
} from 'react-hook-form';
import {
  useNavigate,
} from 'react-router-dom';
import {
  useAnalytics,
  useFirestore
} from "reactfire";
import { BaseEvent, Event } from "../../FirestoreConverters";
import { logEvent } from "firebase/analytics";
import { CollectionTypes } from "../Page/CreateEvent";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LinkButton from "../General/LinkButton";
import DatePicker from "tailwind-datepicker-react";

type GiftingFormValues = {
  eventName: string;
  eventDate: string;
  eventType: string;
  message: string;
}

type FormProps = {
  event?: Event,
  user: User,
  setEventType?: (type: string) => void,
}

export const eventTypeList = [
  {
    value: "",
    name: "Select a Jar Type",
  },
  {
    value: "wedding",
    name: "Wedding",
  },
  {
    value: "engagement",
    name: "Engagement",
  },
  {
    value: "baptism",
    name: "Christening/Baptism",
  },
  {
    value: "christmas",
    name: "Christmas",
  },
  {
    value: "birthday",
    name: "Birthday",
  },
  {
    value: "leaving",
    name: "Leaving/Retirement",
  },
  {
    value: "custom",
    name: "Other",
  }
]

export const CreateGiftingForm = ({user, event, setEventType}: FormProps) => {

  const [, setDocumentTitle] = useDocumentTitle("Create Jar")
  useEffect(() => {
    if(event) setDocumentTitle(`Edit Jar "${event.name}"`)
  }, [event, setDocumentTitle])

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    setError,
    watch,
    setValue,
    trigger
  } = useForm<GiftingFormValues>({
    mode: "onChange"
  });
  const firestore = useFirestore()
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const eventType = watch("eventType")
  useEffect(() => {
    if(!setEventType || !eventType || eventType === "") return
    setEventType(eventType)
  }, [eventType, setEventType])

  const saveEvent = async (data: GiftingFormValues) => {
    // We need the organiser ref
    if (!user) throw new Error("User is null")

    // Check if an event is in the past
    if (event && event.hasEnded) throw new Error("Can't update past event")

    // Create the user ref
    const userRef = doc(firestore, "users", user.uid)

    const date = new Date(Date.parse(data.eventDate))
    date.setHours(23, 59, 59, 999)

    // Construct the occasion data to save
    const eventData: BaseEvent = {
      //@todo This needs to be change to use Timestamp
      date: date,
      name: data.eventName.trim(),
      message: data.message.trim(),
      type: data.eventType,
      isGifting: true,
      feeIncluded: false,
      organiser: userRef,
      createdAt: event?.createdAt || new Timestamp(Date.now()/1000, 0),
      isDeleted: false,
    }

    // If we already have an event, then we update, if not, we save
    let eventRef
    if (event) {
      eventRef = doc(firestore, "events", event.id)
      await updateDoc(eventRef, eventData)
    } else {
      eventRef = await addDoc(collection(firestore, "events"), eventData)
    }
    return eventRef
  }

  const formatDate = (date: Date) => {
    const dt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    const result = dt.toISOString().split('T')[0]

    return result
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmit: SubmitHandler<GiftingFormValues> = async data => {
    setIsLoading(true)
    try {
      const event = await saveEvent(data)
      logEvent(analytics, "event_create", {
        jarTheme: data.eventType,
        openEnded: false,
        jarType: CollectionTypes.GIFTING
      })
      navigate(`/jars/${event.id}`)
    } catch (e: any) {
      setError("message", {message: "Couldn't create jar, please try again"})
      setIsLoading(false)
      logEvent(analytics, "exception", {
        description: e.toString()
      })
    }
  }

  const [showCalendar, setShowCalendar] = useState(false)
  const [jarDate, setJarDate] = useState<Date>()

  useEffect(() => {
    if (jarDate === undefined) return
    setValue("eventDate", formatDate(jarDate))
    trigger("eventDate")
  }, [jarDate, setValue, trigger])

  const eventOptions = eventTypeList.map((v) => {
    return <option value={v.value} key={v.value}>{v.name}</option>
  })

  return (
    <div className="create-occasion-form text-center">
      {event === undefined ? <p className="">Fill out the information below to create your jar!</p> : <p className="pb-4">Use the form below to update your jar.</p>}
      <form onSubmit={handleSubmit(onSubmit)} >
        <fieldset disabled={isLoading === true || (event && event.hasEnded)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="md:col-span-2">
              <label className="block text-left uppercase font-bold py-2">
                Jar Name:
              </label>
              <input
                type="text"
                placeholder="What are you celebrating?"
                {...register("eventName", {
                  required: "Please enter a name for your jar",
                  maxLength: 150
                })}
                className="border p-2 w-full border-my-grey h-10 align-middle text-center placeholder:text-my-grey outline-my-orange capitalize"
                defaultValue={event?.name}
              />
              {errors.eventName && <p className="text-red-600">{errors.eventName.message}</p>}
            </div>
            <div>
              <label className="block text-left uppercase font-bold py-2">
                Jar Type:
              </label>
              <select
                {
                  ...register(
                    "eventType",
                    {
                      required: "Please select the type of your jar",
                      minLength: 1
                    },
                  )
                }
                className="border p-2 w-full border-my-grey bg-white h-10 align-middle rounded-none text-center placeholder:text-my-grey outline-my-orange"
                defaultValue={event?.type}
              >
                {eventOptions}
              </select>
              {errors.eventType && <p className="text-red-600">{errors.eventType.message}</p>}
            </div>
            <div>
              <label className="block text-left uppercase font-bold py-2">
                Jar close date:
              </label>
              <div className="border border-my-grey h-10 flex gap-2">
                <div className="relative cursor-pointer w-full">
                  <DatePicker
                    onChange={setJarDate}
                    show={showCalendar}
                    setShow={setShowCalendar}
                    classNames="bg-white dark:bg-white rounded-none w-full dark:test-gray-500"
                    options={{
                      defaultDate: event? event.date : null,
                      clearBtn: false,
                      theme: {
                        background: "dark:bg-white bg-white",
                        todayBtn: "dark:bg-my-olive bg-my-olive hover:bg-my-orange dark:hover:bg-my-orange outline-none",
                        clearBtn: "",
                        icons: "dark:bg-white bg-white text-my-grey dark:text-my-grey hover:bg-my-olive dark:hover:bg-my-olive dark:hover:text-white",
                        text: "!text-my-grey !dark:text-my-grey hover:bg-my-olive dark:hover:bg-my-olive hover:text-white dark:hover:text-white",
                        disabledText: "!text-gray-200 !dark:text-gray-200 hover:bg-white dark:hover:bg-white hover:text-gray-100 dark:hover:text-gray-100 hover:cursor-not-allowed",
                        input: "!dark:bg-transparent !bg-transparent border-none py-2 text-center !text-my-grey !dark:text-my-grey outline-my-orange rounded-none font-poppins text-md cursor-pointer",
                        inputIcon: "",
                        selected: "!dark:text-white !text-white bg-my-orange",
                      },
                      minDate: new Date(Date.now()-24*60*60*1000),
                      inputPlaceholderProp: "Select Date",
                    }}
                  />
                </div>
                <input
                  type="date"
                  {
                    ...register(
                      "eventDate",
                      {
                        required: "Please select a date",
                        maxLength:10,
                        validate: (v) => {
                          if (v === undefined) return "Please select a date"

                          // only validate if a date is selected
                          const d = new Date(Date.parse(v))
                          const today = new Date()
                          today.setHours(0, 0, 0, 0)
                          d.setHours(23, 59, 59, 999)
                          if (d < today) {
                            return "Date cannot be in the past"
                          }
                        }
                      }
                    )
                  }
                  className="hidden"
                  defaultValue={event && event.date ? formatDate(event.date) : ""}
                />
              </div>
              {errors.eventDate && <p className="text-red-600">{errors.eventDate.message}</p>}
            </div>
            <div className="md:col-span-2 mb-2">
              <label className="block text-left uppercase font-bold py-2">
                Describe your jar:
              </label>
              <textarea
                placeholder="Tell your contributors what you're collecting for..."
                {...register("message", {required: "Please tell us a bit more", maxLength: {
                  value: 500,
                  message: "The maximum length is 500 characters"
                }})}
                className="border border-my-grey p-2 w-full h-32 placeholder:text-my-grey outline-my-orange"
                defaultValue={event?.message}
              />
              {errors.message && <p className="text-red-600">{errors.message.message}</p>}
            </div>
          </div>
          {/* <button type="submit" className="inline-block text-center uppercase border border-my-olive hover:border-my-orange border-solid bg-my-olive hover:bg-my-orange font-bold text-white w-full max-w-xs mx-auto py-4"> */}
          <LinkButton
            type="submit"
            backgroundColour="my-olive"
            disabled={isLoading}
            >
              {isLoading === true ?
                <>
                  <div className="relative">{!event ? "Creating" : "Updating"}
                  <svg className="animate-spin h-6 w-6 absolute left-8 inline" viewBox="0 0 24 24">
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  </div>
                </>
              : <>
              {!event ? "Create Jar!" : "Update Jar"}
              </>}
            </LinkButton>
            {/* </button> */}
        </fieldset>
      </form>
    </div>
  );
}