import LinkButton from "../General/LinkButton"

type BillTypeSelectProps = {
  setType: (type?: string) => void
}

const BillTypeSelect = ({setType}: BillTypeSelectProps) => {
  return <div className="p-4">
    <div className="text-center my-auto p-4 md:p-0">
      <LinkButton type="button" clickHandler={() => setType("instant")} backgroundColour="my-olive">
        Instant Bill
      </LinkButton>
      <div className="relative flex py-5 items-center">
        <div className="flex-grow border-t border-my-olive"></div>
        <span className="flex-shrink mx-4 text-my-olive">Or</span>
        <div className="flex-grow border-t border-my-olive"></div>
      </div>
      <LinkButton type="button" clickHandler={() => setType("email")} backgroundColour="my-olive">
        Send Bill via Email
      </LinkButton>
    </div>
  </div>
}

export default BillTypeSelect