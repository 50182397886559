import { Outlet, useLocation } from "react-router-dom";

import MainNav from "./MainNav";
import Footer from "./Footer";
import CookieBanner from "../Cookies/CookieBanner";
import { useEffect } from "react";
import { useAnalytics } from "reactfire";
import { logEvent } from "firebase/analytics";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

function Layout() {
  const analytics = useAnalytics()
  const { pathname } = useLocation()
  useDocumentTitle()

  useEffect(() => {
    logEvent(analytics, "page_view", {page_location: pathname})
  }, [pathname, analytics])

  return (
    <div className="text-white text-center font-poppins break-words relative min-h-screen flex flex-col">
      <div>
        <MainNav />
      </div>

      <div className="flex-grow bg-white text-my-grey flex flex-col">
        <Outlet />
      </div>

      {/*
      <div className="testimonials hidden max-w-screen-lg mx-6 md:mx-auto">
        <div>
          What do people say?
        </div>
        <div className="grid grid-cols-6 justify-center">
          <div className="">
            &lt;
          </div>
          <div className="col-span-4">
            some awesome words about how great it was to actually receive cash and have it in my account within a x days!
          </div>
          <div className="">
            &gt;
          </div>
        </div>
      </div>
      */}

      <Footer />

      <CookieBanner />
    </div>
  );
}

export default Layout