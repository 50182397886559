import { PropsWithChildren, ReactNode } from "react";

const Tooltip = ({ children, text, className }: PropsWithChildren<{
  text: string | ReactNode,
  className?: string
}>) => {
  return (
    <div className={`relative group ${className ? className : ""}`}>
      {children}
      <div className="absolute left-1/2 bottom-full transform -translate-x-1/2 mb-2 hidden group-hover:block bg-my-grey text-white text-xs rounded py-1 px-2 z-10">
        {text}
      </div>
    </div>
  );
};

export default Tooltip;