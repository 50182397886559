import { PaymentStatus } from "../../FirestoreConverters"
import Tooltip from "./Tooltip"

const PaymentStatusIcon = ({status}: {status: PaymentStatus}) => {
  return <>
    {status === PaymentStatus.PAID ?
      <Tooltip text="Payment Complete">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-my-olive">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </Tooltip>
    : null}
    {status === PaymentStatus.PENDING ?
      <Tooltip text="Payment Pending">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-gray-400">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </Tooltip>
    : null}
    {status === PaymentStatus.FAILED ?
      <Tooltip text="Payment Failed">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-red-300">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
        </svg>
      </Tooltip>
    : null}
    {status === PaymentStatus.CANCELLED ?
      <Tooltip text="Payment Cancelled">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-red-600">
          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </Tooltip>
    : null}
  </>
}

export default PaymentStatusIcon