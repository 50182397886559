import {CreateGiftingForm} from "../Event/CreateGiftingForm"
import { CreateGeneralForm } from "../Event/CreateGeneralForm";
import { useOutletContext } from "react-router-dom";
import { MyAccountProps } from "./MyAccount";

interface CreateEventProps {
  type: CollectionTypes
}

export enum CollectionTypes {
  GENERAL = "general",
  GIFTING = "gifting"
}

const CreateEvent = ({type}: CreateEventProps) => {
  const {setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()
  setDocumentTitle("Create a Jar")
  setSubTitle(`${type === CollectionTypes.GIFTING ? "Gifting" : "Collecting"} money online made easy`)

  const {authUser} = useOutletContext<MyAccountProps>()

  return (
    <div className="text-left p-4 md:p-0 max-w-screen-md mx-auto w-full">
      {type === CollectionTypes.GIFTING ?
        <CreateGiftingForm user={authUser} />
        :
        <CreateGeneralForm user={authUser} />
    }
    </div>
  );
}

export default CreateEvent