import Header from "./Home/Header"
import Body from "./Home/Body"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"

export default function Home() {
  useDocumentTitle()

  return (
    <>
      <Header />
      <Body />
    </>
  )
}