import {
  useState
} from "react";
import {
  Auth,
  AuthErrorCodes,
  updateProfile,
  reload,
  User,
  EmailAuthProvider,
  linkWithCredential,
  createUserWithEmailAndPassword,
  UserCredential,
  // sendEmailVerification,
} from "firebase/auth";
import {
  SubmitHandler,
  useForm
} from 'react-hook-form';
import {
  useAnalytics,
  useAuth,
  useFirestore,
  // useFunctions,
} from "reactfire";
import {
  doc,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';
// import { httpsCallable } from "firebase/functions";
import {
  Link,
} from "react-router-dom";
import { logEvent } from "firebase/analytics";
import LinkButton from "../General/LinkButton";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  marketingConsent: boolean;
  acceptTC: boolean;
}

interface Props {
  setUser: (user: User) => void
}
const Register: React.FC<Props> =  ({setUser}) => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: "all"
  });

  const analytics = useAnalytics()
  // const functions = useFunctions()
  // const sendAccountCreateEmails = httpsCallable<User, Promise<any>>(functions, "sendEmailOnAccountCreateV2")

  // Firebase Auth
  const auth = useAuth()

  // Firebase RTDB
  const firestore = useFirestore()

  async function registerUser(auth: Auth, email: string, password: string, firstName: string) {
    try {
      console.log("creating credential")
      const credential = EmailAuthProvider.credential(email, password)
      let user: UserCredential;
      if (auth.currentUser !== null) {
        console.log("linking account")
        user = await linkWithCredential(auth.currentUser, credential)
      } else {
        console.log("creating with un/pw")
        user = await createUserWithEmailAndPassword(auth, email, password)
      }
      console.log("upating profile")
      await updateProfile(user.user, {
        displayName: firstName.trim()
      })
      // console.log("sending welcome emails")
      // await sendEmailVerification(user.user)
      console.log("Sending sign up analytics event")
      logEvent(analytics, "sign_up")
      console.log("reloading user")
      await reload(user.user)
      return user.user
    } catch (error: any) {
      if (error.name === "FirebaseError") {
        const errorCode = error.code;
        if (errorCode === AuthErrorCodes.INVALID_EMAIL) {
          setError('email', { type: 'custom', message: 'Please enter a valid email address' });
        }
        if (errorCode === AuthErrorCodes.EMAIL_EXISTS) {
          setError('email', { type: 'custom', message: 'An account with this email already exists' });
        }
        if (errorCode === AuthErrorCodes.WEAK_PASSWORD) {
          setError('password', { type: 'custom', message: 'Please enter a stronger password' });
        }
      } else {
        console.log(error.message)
        // let message = String(error)
        // console.log(message)

      }
      console.log(error.message)
      throw error
    }
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true)
    try {
      const user: User = await registerUser(auth, data.email.trim(), data.password, data.firstName.trim())

      await setDoc(doc(firestore, "users", user.uid), {
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email.trim(),
        marketing: data.marketingConsent,
        createdAt: serverTimestamp()
      })
      setUser(user)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      return
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="register-form pb-4 text-center mx-auto max-w-xs">
      <h1 className="text-center text-2xl pb-2 uppercase font-bold">Create an account</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isLoading} className="grid grid-cols-2 gap-2">
          <div>
            <input
              type="text"
              placeholder="First name"
              {...register("firstName", {
                required: "Please enter your first name",
                maxLength: 80,
                setValueAs: (v) => {
                  return v.toLowerCase().split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
                }
              })}
              className="border p-2 w-full outline-my-orange capitalize"
            />
            {errors.firstName && <p className="text-red-600">{errors.firstName.message}</p>}
          </div>

          <div>
            <input
              type="text"
              placeholder="Last name"
              {...register("lastName", {
                required: "Please enter your last name",
                maxLength: 100,
                setValueAs: (v) => {
                  return v.toLowerCase().split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
                }
              })}
              className="border p-2 w-full outline-my-orange capitalize"
            />
            {errors.lastName && <p className="text-red-600">{errors.lastName.message}</p>}
          </div>

          <div className="col-span-2">
            <input
              type="email"
              placeholder="Email"
              {...register("email", {required: "Please enter your email", pattern: /^\S+@\S+$/i})}
              className="border p-2 w-full outline-my-orange"
            />
            {errors.email && <p className="text-red-600">{errors.email.message}</p>}
          </div>

          <div className="col-span-2">
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                {...register("password", {
                  validate: {
                    length: (v) => {return v.length > 7 ? true : "Password must be at least 8 characters long"},
                    containsNumber: (v) => {return /\d/.test(v) ? true : "Password must contain at least one number"},
                    containsSpecialChar: (v) => {return /\p{P}/u.test(v) ? true : "Password must contain at least one special character"},
                  }
                })}
                className="border p-2 w-full outline-my-orange"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="p-2 absolute right-0"
              >
                {showPassword ?
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                }
              </button>
            </div>
            {errors.password && errors.password.types ?
              <ul className="list-disc text-left text-sm list-inside">
                {Object.values(errors.password.types).map((e, i) => {
                  return <li className="text-red-600" key={i}>{e}</li>
                })}
              </ul>
            : null }
          </div>

          <div className="text-left flex col-span-2">
            <input
              type="checkbox" placeholder="Marketing Consent" {...register("marketingConsent", {
                value: true
              })}
              className="mr-2 outline-my-olive accent-my-olive text-white"
            />
            <label className="text-xs">
              Please keep me updated on the latest Flossi news and promotions.
            </label>
          </div>

          <div className="col-span-2">
            {/* <input type="submit" value="Register" className="inline-block text-center uppercase border border-my-olive hover:border-my-orange border-solid bg-my-olive hover:bg-my-orange font-bold text-white w-full max-w-xs mx-auto py-4" /> */}
            <LinkButton type="submit" backgroundColour="my-olive">Register</LinkButton>
          </div>

        </fieldset>

        <p className="text-xs pt-2">By clicking &quot;Register&quot;, you agree to the <Link to={"/terms"} className={"font-bold underline text-my-orange hover:text-my-grey"}>Terms &amp; Conditions</Link> and <Link to={"/privacy"} className={"font-bold underline text-my-orange hover:text-my-grey"}>Privacy Policy</Link></p>

      </form>
    </div>
  );
}

export default Register