import { PropsWithChildren } from "react";
import { Link } from "react-router-dom"

interface Props {
  url?: string;
  text?: string;
  backgroundColour?: "my-olive" | "my-orange";
  clickHandler?: () => void;
  disabled?: boolean;
}

// const LinkButton = ({
//   url,
//   clickHandler = () => {},
//   text,
//   backgroundColour,
//   disabled
// }: Props) => {


//   let btnClass = "inline-block text-center uppercase border border-my-orange hover:border-my-olive border-solid bg-my-orange hover:bg-my-olive font-bold text-white w-full max-w-xs mx-auto py-4"

//   if (backgroundColour === "my-olive") {
//     btnClass = "inline-block text-center uppercase border border-my-olive hover:border-my-orange border-solid bg-my-olive hover:bg-my-orange font-bold text-white w-full max-w-xs mx-auto py-4"
//   }

//   if (!url) {
//     return (
//       <button
//         className={btnClass}
//         onClick={() => clickHandler()}
//         disabled={disabled}
//       >
//         {text}
//       </button>
//     )
//   }

//   return (
//     <Link
//       to={url}
//       className={btnClass}
//     >
//       {text}
//     </Link>
//   )
// }


interface NewProps extends Props {
  type: "link" | "button" | "submit";
}

const LinkButton = ({
  type,
  children,
  backgroundColour,
  disabled,
  url,
  clickHandler,
}: PropsWithChildren<NewProps>) => {
  const baseClass = "inline-block text-center uppercase border border-solid font-bold text-white w-full max-w-xs mx-auto py-4 disabled:bg-gray-300 disabled:border-gray-300 disabled:hover:bg-gray-200 disabled:hover:border-gray-300 cursor-pointer disabled:cursor-not-allowed"
  let colourClass = "border-my-orange hover:border-my-olive bg-my-orange hover:bg-my-olive"

  if (backgroundColour === "my-olive") {
    colourClass = "border-my-olive hover:border-my-orange bg-my-olive hover:bg-my-orange"
  }

  if (type === "link") {
    if (url === undefined) {
      throw new Error("Button set as link without url")
    }
    return <Link
    to={url}
    className={`${baseClass} ${colourClass}`}
    >
      {children}
    </Link>
  }

  if (type === "button" && clickHandler === undefined) {
    throw new Error("Button type is button, but no click handler set")
  }
  return <button
  className={`${baseClass} ${colourClass}`}
  type={type}
  onClick={type === "button" && clickHandler !== undefined ? (() => clickHandler()) : undefined}
  disabled={disabled}
  >
      {children}
    </button>
}

export default LinkButton