import { DocumentSnapshot } from "firebase/firestore"
import { Event, Gift, PaymentJobWebhookUrls, PaymentRequest } from "../FirestoreConverters"

interface CashflowsParameters {
  returnUrlCancelled: string;
  returnUrlFailed: string;
  returnUrlSuccess: string;
  webhookUrl: PaymentJobWebhookUrls;
}

export const createPaymentReq = (
  occasion: Event,
  giftDoc: DocumentSnapshot<Gift>,
  parameters: CashflowsParameters
): PaymentRequest => {
  const gift = giftDoc.data()
  if (gift === undefined || gift.id === undefined) throw new Error("Gift has no ID, can't create payment req")

  const req: PaymentRequest = {
    amountToCollect: gift.totalAmount.toString(),
    type: "Payment",
    currency: "GBP",
    locale: "en_GB",
    order: {
      orderNumber: `${occasion.id}/${gift.id}`,
    },
    parameters
  }

  if (gift.cardholderFName !== undefined && gift.cardholderLName !== undefined) {
    req.order.billingAddress = {
      firstName: gift.cardholderFName,
      lastName: gift.cardholderLName
    }
  }

  if (gift.email !== undefined) {
    req.order.billingIdentity = {
      emailAddress: gift.email
    }
  }

  return req
}