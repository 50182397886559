import LinkButton from "../General/LinkButton"

const RedeemInProgress = () => {
  return <div className="mx-auto max-w-screen-md text-left text-my-grey p-4">
    <h1 className="font-bold uppercase text-2xl text-center m-2">Redeem in Progress</h1>

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-40 mx-auto text-my-olive">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>

    <p className="text-center">
      Thank you, your redeem request has been submitted.
      Once your request has been completed, you will receive an email.
    </p>

    <div className="text-center pt-4">
      <LinkButton type="link" url="../">
        Back to Jar
      </LinkButton>
    </div>
  </div>
}

export default RedeemInProgress