import { User } from "firebase/auth"
import LoginForm from "../User/Login"
import Register from "../User/Register"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import Hero from "../General/Hero"

interface Props {
  setUser: (user: User) => void
}
const LoginOrRegister: React.FC<Props> =  ({setUser}) => {
  useDocumentTitle("Login or Create an Account")

  return (
    <div className="bg-white">
      <Hero
        title="Login / Register"
      />
      <div className="mx-auto max-w-screen-lg text-left text-my-grey p-8 grid grid-cols-1 md:grid-cols-2">
        <LoginForm setUser={setUser} />
        <Register setUser={setUser} />
      </div>
  </div>)
}
export default LoginOrRegister