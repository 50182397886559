import { httpsCallable } from "firebase/functions"
import { useMemo, useState } from "react"
import { useFunctions } from "reactfire"
import { AcquiredPayee } from "../../FirestoreConverters"
import Loading from "../General/Loading"
import LinkButton from "../General/LinkButton"
import LinkBankAccountForm from "./LinkBankAccountForm"
import Modal from "../General/Modal"
import { MyAccountProps } from "../Page/MyAccount"
import { useOutletContext } from "react-router-dom"

const ManageBankAccounts = () => {

  const {userInfo: {
    acquiredCustomerId,
    IDIsVerified

  }} = useOutletContext<MyAccountProps>()
  const [showAddAccountModal, setShowAddAccountModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState<false | AcquiredPayee>(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  const functions = useFunctions()
  const listBankAccounts = httpsCallable<Record<string, never>, AcquiredPayee[]>(functions, "listBankAccounts")
  const unlinkBankAccount = httpsCallable<AcquiredPayee, Record<string, never>>(functions, "unlinkBankAccount")
  const [accounts, setAccounts] = useState<false | AcquiredPayee[]>()
  const [bankAccountAdded, setBankAccountAdded] = useState<boolean>(false)
  useMemo(() => {
    if(accounts !== undefined) return
    if(acquiredCustomerId === undefined) {
      return setAccounts([])
    }
    listBankAccounts()
      .then(({data}) => {
        console.log({data})
        setAccounts(data)
      })
      .catch(e => {
        console.log(e)
        setAccounts(false)
      })
  }, [listBankAccounts, accounts, acquiredCustomerId])

  return <div className="p-4">
    {showAddAccountModal === true ?
      <Modal title="Add new Bank Account" closeFunction={() => setShowAddAccountModal(false)}>
        <div className="py-4">
          <LinkBankAccountForm onComplete={() => {
            setBankAccountAdded(true)
            setAccounts(undefined)
            setShowAddAccountModal(false)
          }}/>
        </div>
      </Modal>
    : null}

    {bankAccountAdded === true ?
      <Modal title="Bank account added!" closeFunction={() => setBankAccountAdded(false)}>
        <div className="py-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-bottom text-my-olive max-w-96 mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          <p className="text-center">Your bank account has been successfully linked!</p>
        </div>
      </Modal>
    : null}

    {showDeleteModal !== false ?
      <Modal title="Unlink Bank Account" closeFunction={() => setShowDeleteModal(false)}>
      <div className="py-4">
        <p>Please confirm the unlinking of the account nicknamed:</p>
        <p className="py-2 font-bold text-xl">{showDeleteModal.nickname} (Ending: {showDeleteModal.ending})</p>
        <LinkButton
          type="button"
          disabled={deleteInProgress}
          clickHandler={async () => {
            setDeleteInProgress(true)
            try {
              await unlinkBankAccount(showDeleteModal)
            } catch (e) {
              console.log(e)
            }
            setDeleteInProgress(false)
            setAccounts(undefined)
            setShowDeleteModal(false)
          }}
        >
          Confirm
        </LinkButton>
      </div>
      </Modal>
    : null}

    <h1 className="uppercase font-bold text-xl py-2">Manage Linked Bank Accounts</h1>
    {IDIsVerified === true && accounts !== undefined && accounts !== false && accounts.length > 0 ?
      <p>You can have a maximum of two linked bank accounts. You can unlink an existing account by selecting it below</p>
    : null}
    {IDIsVerified === false ?
      <div>
        <p className="p-2">Before you can add a bank account, you are required to verify your identity.</p>
        <LinkButton url="/my-account/verify" type="link">
          Verify Now
        </LinkButton>
      </div>
    :
      <div className="flex flex-col max-w-sm mx-auto gap-2 p-4">
        {accounts !== false && accounts !== undefined ?
          accounts.length > 0 ?
            accounts.map(acc => {
              return <button
                className="flex-1 border border-my-grey rounded-sm text-center px-2 py-4 min-w-60 hover:bg-my-olive hover:text-white max-w-"
                onClick={() => {
                  setShowDeleteModal(acc)
                }}
              >
                <span className="font-bold uppercase block">
                  {acc.nickname}
                </span>
                <span className="text-sm">Ending: {acc.ending}</span>
              </button>
            })
          :
            <p>You have no linked accounts. Link one using the button below.</p>
        :
            accounts === undefined ? <Loading />
          :
            <p>Failed to load your linked bank accounts. Please try again.</p>
        }
      </div>
    }
    {IDIsVerified === true && accounts !== undefined && accounts !== false && accounts.length < 2 ?
      <div className="pb-2">
        <LinkButton type="button" clickHandler={() => {setShowAddAccountModal(true)}}>Link new Bank Account</LinkButton>
      </div>
    : null}
    <LinkButton type="link" url="../">Back to my account</LinkButton>
  </div>
}

export default ManageBankAccounts