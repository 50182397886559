import { httpsCallable } from "firebase/functions"
import { AcquiredPayee, Event } from "../../FirestoreConverters"
import { useFunctions } from "reactfire"
import LinkButton from "../General/LinkButton"
import { useState } from "react"
import Loading from "../General/Loading"

const RedeemForm = ({payee, event}: {payee: AcquiredPayee, event: Event}) => {
  const functions = useFunctions()
  const redeemFunds = httpsCallable<{payeeId: string, eventId: string}, {result: string}>(functions, "redeemFunds")

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const doRedeemRequest = async (payee: AcquiredPayee, event: Event) => {
    setError(undefined)
    setIsLoading(true)
    try {
      await redeemFunds({payeeId: payee.payeeId, eventId: event.id})
    } catch (e) {
      setError("Sorry, there was an error. Please try again later.")
      console.log(e)
    }
    setIsLoading(false)
  }

  return isLoading === true ?
    <div className="text-center p-2">
      <p>Redeeming your contributions. Please wait.</p>
      <Loading />
    </div>
  :
    <div className="text-center p-2">
      {error !== undefined ?
        <div className="border border-red-500 bg-red-200 text-red-500 p-4 text-center">
          {error}
        </div>
      : null}
      <div className="py-2">
        <p>Redeem Contributions to account:</p>
        <p className="font-bold uppercase text-xl">{payee.nickname}</p>
        <p className="text-sm">(Ending: {payee.ending})</p>
        <p className="p-2 text-xl">Amount: &pound;{event.totalAmount.toFixed(2)}</p>
      </div>
      <div className="text-center">
        <LinkButton type="button" clickHandler={() => {
          doRedeemRequest(payee, event)
        }}>
          Confirm
        </LinkButton>
      </div>
    </div>
}

export default RedeemForm