import { useOutletContext } from "react-router-dom";
import { Event } from "../../FirestoreConverters";
import GiftContributionForm from "./GiftContributionForm";
import { User } from "firebase/auth";
import GeneralContributionForm from "./GeneralContributionForm";

const GiftSelectForm = () => {
  const {eventInfo, userInfo} = useOutletContext<{eventInfo: Event, userInfo: User}>()

  if (eventInfo.isGifting === true) return <GiftContributionForm event={eventInfo} user={userInfo} />
  return <GeneralContributionForm event={eventInfo} user={userInfo} />
}

export default GiftSelectForm