import { PropsWithChildren } from "react"
import { useOutsideClick } from "../../hooks/useOutsideClick"

interface ContextMenuProps {
  showMenu: boolean;
  setShowMenu: (show: boolean) => void;
}
const ContextMenu = ({children, showMenu, setShowMenu}: PropsWithChildren<ContextMenuProps>) => {

  const ref = useOutsideClick(() => {
    if(showMenu === true) {
      setShowMenu(false)
    }
  })

  return <>
    <button
      className="align-middle"
      onClick={() => {
        if (showMenu === false) {
          return setShowMenu(true)
        }
        setShowMenu(false)
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
      </svg>
    </button>
    <div
      className={`${showMenu === true ? "block" : "hidden"} text-md text-left ring-1 ring-gray-200 shadow-xl rounded-md absolute right-0 bg-white px-2 w-48 z-10 divide-y`}
      ref={ref}
    >
      {children}
    </div>
  </>
}

export default ContextMenu