import { addDoc, collection, DocumentSnapshot, Firestore, getDoc, updateDoc } from "firebase/firestore";
import { Event, FirestoreGiftConverter, Gift, PaymentJob, PaymentJobWebhookUrls, PaymentRequest, PaymentStatus } from "../FirestoreConverters";
import { createPaymentReq } from "./Cashflows";
import { Functions, httpsCallable } from "firebase/functions";

type GiftAmounts = {
  feeAmount: number;
  giftAmount: number;
  totalAmount: number;
}

export const calculateGiftAmounts = (event: Event, amount: number): GiftAmounts => {
  const feeLookup = (giftAmt: number) => {
    if (giftAmt === 0) return 0
    // const txnFee = 0.11
    // const txnPc = 0.006
    // return (giftAmount * txnPc) + txnFee
    if (giftAmt === 0) {
      return 0
    } else if (giftAmt < 2) {
      return 0.15
    } else {
      return (giftAmount * 0.01) + 0.2
    }/*if (giftAmt < 5) {
      return 0.4
    } else * /
    if (giftAmt < 10) {
      //return 0.6
      return 0
    } else if (giftAmt < 20) {
      return 0.75
    } else if (giftAmt < 50) {
      return 1
    } else if (giftAmt < 75) {
      return 1.25
    } else if (giftAmt < 100) {
      return 1.5
    } else if (giftAmt < 125) {
      return 1.75
    } else if (giftAmt < 150) {
      return 2
    } else if (giftAmt < 225) {
      return 2.50
    } else if (giftAmt < 300) {
      return 3
    } else {
      return giftAmt*0.01
    }
    */
  }

  let giftAmount = amount
  const feeAmount = parseFloat(feeLookup(amount).toFixed(2))
  if (event.feeIncluded) {
    giftAmount = amount-feeAmount
  }
  const totalAmount = feeAmount+giftAmount

  return {
    feeAmount,
    giftAmount,
    totalAmount
  }
}

export const saveGift = async (firestore: Firestore, functions: Functions, event: Event, gift: Gift): Promise<DocumentSnapshot<Gift>> => {
  const createPaymentJob = httpsCallable<PaymentRequest, PaymentJob>(functions, "createPaymentJob")
  const savedGift = await createGift(firestore, event, gift)

  const billUrl = `/contribute-to/${event.id}/bill/${savedGift.id}`

  const paymentJobParams = {
    returnUrlCancelled: billUrl,
    returnUrlFailed: billUrl,
    returnUrlSuccess: `/contribute-to/${event.id}/confirmation`,
    webhookUrl: PaymentJobWebhookUrls.PaymentUpdate
  }
  const paymentReq = createPaymentReq(event, savedGift, paymentJobParams)
  await addPaymentReqToGift(savedGift, paymentReq)
  const paymentJob = (await createPaymentJob(paymentReq)).data
  await addPaymentJobToGift(savedGift, paymentJob)

  const updatedGift  = await getDoc(savedGift.ref)
  const updatedData = updatedGift.data()
  if (updatedData === undefined) {
    throw new Error("Updated gift data undefined")
  }

  return updatedGift
}

/**
 * Save the gift in Firestore
 *
 * @param giftData Gift
 * @returns DocumentSnapshot<Gift>
 * @throws Error
 */
const createGift = async (firestore: Firestore, event: Event, giftData: Gift): Promise<DocumentSnapshot<Gift>> => {
  let ref = undefined
  try {
    ref = await addDoc(
      collection(
        firestore, `events/${event.id}/gifts`)
          .withConverter(FirestoreGiftConverter),
      giftData
    )
  } catch (e: any) {
    console.log("Failed to save gift")
    console.log(e)
    throw new Error(e.message)
  }

  try {
    if (ref === undefined) throw new Error("Don't have a gift ref")
    const doc = await getDoc(ref)
    const data = doc.data()
    if (data === undefined) {
      console.log("Failed to find doc after saving")
      throw new Error("Failed to find doc after saving")
    }
    return doc
  } catch (e: any) {
    console.log("Failed to fetch gift doc")
    console.log(e)
    throw new Error(e.message)
  }
}

const addPaymentReqToGift = async (gift: DocumentSnapshot<Gift>, req: PaymentRequest) => {
  try {
    await updateDoc(gift.ref, {
      paymentReq: req,
    })
  } catch (e: any) {
    console.log(e)
    throw new Error("Couldn't update gift with payment req")
  }
}

const addPaymentJobToGift = async (gift: DocumentSnapshot<Gift>, job: PaymentJob) => {
  try {
    await updateDoc(gift.ref, {
      paymentRef: job.data.reference,
      paymentJob: job,
      status: PaymentStatus.PENDING,
    })
  } catch (e: any) {
    console.log(e)
    throw new Error("Couldn't update gift with payment job")
  }
}