import { useEffect, useState } from "react";
import { useSigninCheck } from "reactfire";
import { User } from "firebase/auth";
import LinkButton from "../../General/LinkButton";

const Header = () => {

  const [user, setUser] = useState<User | null>(null)

  const { status, data: signInCheckResult } = useSigninCheck();

  /*
  I think there's a bug here, more a race condition, where when a user logs in
  their auth status isn't set and we can't render the my-account page
  */
  useEffect(() => {
    if (
      status !== 'loading' && status !== 'error' &&
      signInCheckResult.signedIn === true &&
      signInCheckResult.user?.isAnonymous === false
    ) {
      setUser(signInCheckResult.user)
    } else {
      setUser(null)
    }
  }, [signInCheckResult, status])

  return (
    <div className="header min-h-fit h-screen bg-home-header bg-no-repeat bg-top-left md:bg-top bg-cover xl:bg-cover text-white">
      <div className="title-text content-end md:content-end grid grid-columns-1 place-content-center h-1/2">
        <div>
          <h1 className="text-5xl sm:text-6xl font-poppins font-bold capitalize inline-block">Flossi...&nbsp;</h1>
          <h2 className="text-5xl sm:text-6xl font-poppins font-bold inline-block">it's effortless</h2>
        </div>
        <h3 className="text-2xl uppercase font-poppins">Collecting money online made easy</h3>
      </div>
      <div className="buttons content-center max-w-screen-md gap-4 grid grid-cols-1 sm:grid-cols-2 h-1/2 px-10 md:mx-auto">
        <LinkButton url="/create-jar" type="link">Create Jar</LinkButton>
        <LinkButton url="/jars" type="link">{!user ? "Login" : "My Jars"}</LinkButton>
        <div className="hidden sm:col-span-3">
          Down Arrow
        </div>
      </div>
    </div>
  )
}

export default Header