import { Outlet, useOutletContext } from "react-router-dom"
import LinkButton from "../General/LinkButton";
import { sendEmailVerification } from "firebase/auth";
import Loading from "../General/Loading";
import { MyAccountProps } from "../Page/MyAccount";
import RedeemInProgress from "./RedeemInProgress";
import EventHasBeenClaimed from "./EventHasBeenClaimed";

const Claim = () => {

  const {event, setDocumentTitle, userInfo, authUser} = useOutletContext<MyAccountProps>()
  setDocumentTitle(event !== undefined ? `Redeem contributions for ${event.name}` : "")

  // Send the verification email
  const sendVerification = async () => {
    await sendEmailVerification(authUser)
  }

  // Loading the event info
  if (event === undefined) {
    return <Loading />
  }

  // Redemption already in progress
  if (event.redeemInProgress === true) {
    return <RedeemInProgress />
  }

  // Has end-date and has been redeemed
  if (event.hasBeenClaimed === true) {
    return <EventHasBeenClaimed />
  }

  return (
    <div className="mx-auto max-w-screen-md text-left text-my-grey p-4">
      <h1 className="font-bold uppercase text-2xl text-center m-2">Select Bank Account</h1>

      {authUser.emailVerified !== true ?
        <div className="border-4 border-red-400 bg-gray-100 p-4 max-w-lg mx-auto text-center">
          <p className="p-2">
            You are required to verify your email address before you can redeem your jar.
          </p>
          <LinkButton type="button" clickHandler={sendVerification}>Send verification</LinkButton>
        </div>
      : null}

      {userInfo.IDIsVerified !== true ?
        <div className="border-4 border-red-400 bg-gray-100 p-4 max-w-lg mx-auto text-center">
          <p className="p-2">
            You are required to verify your identity before you can redeem your jar.
          </p>
          <LinkButton type="link" url="/my-account/verify">Verify Identity</LinkButton>
        </div>
      : null}

      {event.totalAmount === 0 ?
        <>
          <div className="border-4 border-red-400 bg-gray-100 p-4 max-w-lg mx-auto text-center">
            <p className="p-2">
              Insufficient funds to redeem
            </p>
          </div>
          <div className="pt-4">
            <LinkButton type="link" url="../">
              Back to jar
            </LinkButton>
          </div>
        </>
      : null}

      {
        event.hasBeenClaimed === false
        && event.redeemInProgress === false
        && event.totalAmount > 0
        && authUser.emailVerified === true
        && userInfo.IDIsVerified === true
      ?
        <Outlet context={{event, userInfo, authUser, setDocumentTitle}}/>
      : null}
    </div>
  )
}

export default Claim