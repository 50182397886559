import { doc, serverTimestamp, setDoc } from "firebase/firestore"
import { SubmitHandler, useForm } from "react-hook-form"
import { useFirestore, useUser } from "reactfire"

type FormValues = {
  email: string;
}

const NewsletterSubscribeForm = () => {
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    //setError
  } = useForm<FormValues>({
    mode: 'onChange'
  })

  const firestore = useFirestore()
  const {data: user} = useUser();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      //const user: User = await registerUser(auth, data.email, data.password, data.firstName)

      await setDoc(doc(firestore, "users", user!.uid), {
        email: data.email,
        marketing: true,
        acceptTC: false,
        createdAt: serverTimestamp()
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex">
        <input
          type="text"
          placeholder="Enter Email..."
          {...register("email", {required: "Please enter your email", pattern: /^\S+@\S+$/i})}
          className="p-2 border-none flex-auto min-w-10 focus:outline-my-olive rounded-none"
        />
        <button type="submit" className="bg-my-grey flex-none w-10">
          <img src="/img/email-orange-icon.png" alt="Subcribe!" className="p-0 m-0 border-none scale-150" />
        </button>
      </div>
      {errors.email && <p className="text text-red-500 border border-red-500 bg-red-300 p-2 m-2 text-center">{errors.email.message}</p>}
    </form>
  )
}

export default NewsletterSubscribeForm