interface HeroProps {
  title: string;
  sub?: string
  bg?: string;
}

const Hero = ({bg, title, sub}: HeroProps) => {
  return <div className={`header ${bg ? bg : "bg-hero-jar"} bg-no-repeat bg-bottom bg-cover pt-14 text-white font-beloved h-72 md:h-96 flex`}>
    <div className="m-auto">
      <h1 className="text-4xl md:text-5xl break-words font-bold">{title}</h1>
      {sub !== undefined ? <h3 className="uppercase px-2">{sub}</h3> : null}
    </div>
  </div>
}

export default Hero