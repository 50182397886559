import {
  signInWithEmailAndPassword,
  AuthErrorCodes,
  User
} from "firebase/auth";
import {
  SubmitHandler,
  useForm
} from 'react-hook-form';
import {
  useAnalytics,
  useAuth,
  useFirestore,
} from "reactfire";
import { doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { logEvent, setUserId } from "firebase/analytics";
import { useState } from "react";
import LinkButton from "../General/LinkButton";

type FormValues = {
  email: string;
  password: string;
}

interface Props {
  setUser: (user: User) => void
}
const Login: React.FC<Props> = ({setUser}) => {
  const analytics = useAnalytics()
  const auth = useAuth()
  // Firebase RTDB
  const firestore = useFirestore()
  const { register, handleSubmit, formState: { errors }, setError } = useForm<FormValues>();

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmit: SubmitHandler<FormValues> = async data => {
    setIsLoading(true)
    try {
      const {user} = await signInWithEmailAndPassword(auth, data.email, data.password)
      console.log(user)
      const userInfo = doc(firestore, "users", user.uid)
      console.log(userInfo)
      setUser(user)
      logEvent(analytics, "login")
      setUserId(analytics, user.uid)
    } catch (error: any) {
      if (error.name === "FirebaseError") {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        if (error.code === AuthErrorCodes.USER_DELETED) {
          setError('email', { type: 'custom', message: 'Account not found' });
        }
        if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          setError('email', { type: 'custom', message: 'An account with this email already exists' });
        }
        if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
          setError('password', { type: 'custom', message: 'Please enter the correct password' });
        }
        if (error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          setError('password', { type: 'custom', message: 'Your account has been disabled due to too many failed login attempts. Please either reset your password, or try again later' });
        }
      } else {
        let message = String(error)
        console.log(message)
      }
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <div className="login-form pb-4 text-center mx-auto w-full">
      <h1 className="text-center text-2xl pb-2 uppercase font-bold">Already have an account?</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isLoading}>
          <div className="mx-auto max-w-xs pb-1">
            <div className="p-1">
              <input
                type="email"
                placeholder="Email"
                {...register("email", {required: "Please enter your email", pattern: /^\S+@\S+$/i})}
                className="border p-2 w-full outline-my-orange"
              />
              {errors.email && <p className="text-red-600">{errors.email.message}</p>}
            </div>

            <div className="p-1">
              <input
                type="password"
                placeholder="Password"
                {...register("password", {required: "Please enter a password"})}
                className="border p-2 w-full outline-my-orange"
              />
              {errors.password && <p className="text-red-600">{errors.password.message}</p>}
            </div>
            <Link to={"/forgotten-password"} className="text-sm text-my-olive hover:text-my-orange font-bold underline block text-left p-1">Forgotten Password?</Link>
          </div>
          {/* <input type="submit" value="Login" className="inline-block text-center uppercase border border-my-olive hover:border-my-orange border-solid bg-my-olive hover:bg-my-orange font-bold text-white w-full max-w-xs mx-auto py-4" /> */}
          <LinkButton type="submit" backgroundColour="my-olive">Login</LinkButton>
        </fieldset>
      </form>
    </div>
  );
}

export default Login