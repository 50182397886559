import { Outlet, useOutletContext } from "react-router-dom"
import { MyAccountProps } from "../Page/MyAccount"

const CreateJarWrapper = () => {
  const context = useOutletContext<MyAccountProps>()

  return <Outlet context={context} />

}

export default CreateJarWrapper