import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form"
import LinkButton from "../General/LinkButton";
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import { useOutletContext } from "react-router-dom";
import { MyAccountProps } from "../Page/MyAccount";

type BankAccount = {
  nickname: string;
  sortCode: number;
  accountNumber: number;
}

const LinkBankAccountForm = ({onComplete}: {onComplete?: () => void}) => {
  const {userInfo, authUser} = useOutletContext<MyAccountProps>()

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    setError,
    setFocus
  } = useForm<BankAccount>({
    mode: "onChange"
  })

  useEffect(() => {
    setFocus("nickname")
  }, [setFocus])

  const functions = useFunctions()
  const linkBankAccount = httpsCallable(functions, "linkBankAccount")

  const [data, setData] = useState<BankAccount>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmit: SubmitHandler<BankAccount> = async (data) => {
    setData(data)
  }

  const saveAccount = async () => {
    if (data === undefined) return

    const {
      nickname,
      sortCode,
      accountNumber
    } = data

    setIsLoading(true)
    try {
      await linkBankAccount({
        userId: authUser.uid,
        accountName: `${userInfo.firstName} ${userInfo.lastName}`,
        nickname,
        sortCode,
        accountNumber
      })

      if (onComplete !== undefined) onComplete()

    } catch (e: any) {
      setError("root", {
        message: "Failed to add bank account"
      })
    }

    setData(undefined)
    setIsLoading(false)
  }

  return data !== undefined ?
    <>
      <p>Please confirm your details below:</p>

      <div className="py-2 grid grid-cols-2 gap-1">
        <div className="font-bold text-right">Nickname:</div>
        <div className="text-left">{data.nickname}</div>
        <div className="font-bold text-right">Name on Account:</div>
        <div className="text-left">{`${userInfo.firstName} ${userInfo.lastName}`}</div>
        <div className="font-bold text-right">Sort Code:</div>
        <div className="text-left">{data.sortCode}</div>
        <div className="font-bold text-right">Account Number:</div>
        <div className="text-left">{data.accountNumber}</div>
      </div>

      <div className="grid grid-cols-1 gap-2">
        <LinkButton
          disabled={isLoading}
          type="button"
          backgroundColour="my-olive"
          clickHandler={() => {
            saveAccount()
          }}
        >
          {isLoading ?
            "Linking..."
            :
            "Confirm"
          }
        </LinkButton>

        <LinkButton disabled={isLoading} type="button" clickHandler={() => {
          setData(undefined)
        }}>
          Cancel
        </LinkButton>
      </div>
    </>

  : <form onSubmit={handleSubmit(onSubmit)}>
    {errors.root !== undefined ?
      <div className="border border-red-500 bg-red-200 text-red-500 p-4 text-center">
        {errors.root.message}
      </div>
    : null}
    <fieldset disabled={isLoading}>
      <div className="mx-auto max-w-md grid grid-cols-1 md:grid-cols-2 gap-2 p-2 md:p-0 text-center">
        <label className="block text-left md:text-right uppercase font-bold md:py-2">
          Account Nickname:
        </label>
        <input
          type="text"
          className="border p-2 w-full outline-my-orange"
          {...register("nickname", {
            required: "Give your account a nickname"
          })}
          autoComplete="off"
          placeholder="My Bank Account"
        />
        {errors.nickname && <p className="text-red-600 md:col-span-2">{errors.nickname.message}</p>}
        <label className="block text-left md:text-right uppercase font-bold md:py-2">
          First Name:
        </label>
        <input
          type="text"
          className="border p-2 w-full outline-my-orange"
          disabled={true}
          value={userInfo.firstName}
        />
        <label className="block text-left md:text-right uppercase font-bold md:py-2">
          Last name:
        </label>
        <input
          type="text"
          className="border p-2 w-full outline-my-orange"
          disabled={true}
          value={userInfo.lastName}
        />
        <label className="block text-left md:text-right uppercase font-bold md:py-2">
          Sort Code:
        </label>
        <input
          type="number"
          className="border p-2 w-full outline-my-orange"
          {...register("sortCode", {
            minLength: {
              value: 6,
              message: "Please enter a valid sort code"
            },
            maxLength: {
              value: 6,
              message: "Please enter a valid sort code"
            },
            pattern: {
              value: /^\d{2}(-)?\d{2}(-)?\d{2}$/,
              message: "Please enter a valid sort codse"
            },
            required: "Please enter your bank account sort code",
          })}
          autoComplete="off"
          placeholder="000000"
        />
        {errors.sortCode && <p className="text-red-600 md:col-span-2">{errors.sortCode.message}</p>}
        <label className="block text-left md:text-right uppercase font-bold md:py-2">
          Account number:
        </label>
        <input
          type="number"
          className="border p-2 w-full outline-my-orange"
          {...register("accountNumber", {
            minLength: {
              value: 8,
              message: "Please enter a valid account number"
            },
            maxLength: {
              value: 8,
              message: "Please enter a valid account number"
            },
            required: "Please enter your bank account number"
          })}
          autoComplete="off"
          placeholder="12345678"
        />
        {errors.accountNumber && <p className="text-red-600 md:col-span-2">{errors.accountNumber.message}</p>}
        <div className="md:col-span-2">
          <LinkButton type="submit" backgroundColour="my-olive">
            Link
          </LinkButton>
        </div>
      </div>
    </fieldset>
  </form>
}

export default LinkBankAccountForm